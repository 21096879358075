<template>
  <ListFilterForm
    :is-filter-enabled="props.isFilterEnabled"
    :initial-filter="initialFilter"
    @toggle-filter="emits('toggleFilter')"
    @set-filter="setParentFilter"
  >
    <template #fields="slotProps">
      <div class="flex flex-col">
        <label for="segmentMame" class="small-label">Название сегмента</label>
        <InputText
          id="segmentMame"
          placeholder="Введите название"
          v-model="slotProps.filter.name"
          aria-describedby="username-help"
        />
      </div>
      <div class="flex flex-col m-t-8">
        <label for="date" class="small-label">Дата создания</label>
        <Calendar
          id="date"
          panelClass="not-extended"
          placeholder="Выберите значение"
          v-model="slotProps.filter.date"
          aria-describedby="date-help"
          showIcon
          iconDisplay="input"
          inputId="icondisplay"
        />
      </div>
      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Статус сегмента</label>
        <MultiSelect
          v-model="slotProps.filter.state"
          optionValue="id"
          :options="segmentsDictionariesStore.statesAllowed"
          optionLabel="display_name"
          placeholder="Выберите значение"
        />
      </div>
      <div class="flex flex-col m-t-8">
        <label for="type" class="small-label">Тип сегмента</label>
        <div
          v-for="sType in segmentsDictionariesStore.types"
          :key="sType.id"
          class="flex type-checkbox"
        >
          <Checkbox
            v-model="slotProps.filter.type"
            :inputId="`type-${sType.id}`"
            :value="sType.id"
          />
          <label :for="`type-${sType.id}`" class="m-l-10">
            {{ sType.display_name }}
          </label>
        </div>
      </div>
      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Категория</label>
        <CategoryTreeInput
          :modelValue="slotProps.filter.category"
          ref="childCategoryTree"
          :isSegmentSetted="false"
          @update:modelValue="
          ($event: any) => (slotProps.filter.category = $event)
        "
        ></CategoryTreeInput>
      </div>
      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Бренд</label>
        <CatalogDataBaseInput
          :useIdOnly="true"
          selectType="brand"
          :isSegmentSetted="false"
          :modelValue="slotProps.filter.brand"
          @update:modelValue="($event) => (slotProps.filter.brand = $event)"
        ></CatalogDataBaseInput>
      </div>
      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Магазин</label>
        <CatalogDataBaseInput
          :useIdOnly="true"
          selectType="shop"
          :isSegmentSetted="false"
          :modelValue="slotProps.filter.shop"
          @update:modelValue="($event) => (slotProps.filter.shop = $event)"
        ></CatalogDataBaseInput>
      </div>
      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Автор</label>
        <CatalogDataBaseInput
          :useIdOnly="true"
          selectType="user"
          :isSegmentSetted="false"
          :modelValue="slotProps.filter.user"
          @update:modelValue="($event) => (slotProps.filter.user = $event)"
        ></CatalogDataBaseInput>
      </div>
    </template>
  </ListFilterForm>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import MultiSelect from "primevue/multiselect";
import Checkbox from "primevue/checkbox";
import CatalogDataBaseInput from "@/widgets/forms/CatalogDataBaseInput.vue";
import CategoryTreeInput from "@/entities/segments/forms/CategoryTreeInput.vue";
import ListFilterForm from "@/widgets/forms/ListFilterForm.vue";

import { useSegmentsDictionariesStore } from "@/store/dictionaries/segments";

const props = defineProps<{
  isFilterEnabled: boolean;
}>();
const emits = defineEmits(["toggleFilter", "setFilter"]);
const segmentsDictionariesStore = useSegmentsDictionariesStore();

const initialFilter = {
  name: "",
  date: null,
  state: [] as number[],
  type: [] as number[],
  user: [] as number[],
  brand: [] as string[],
  category: [] as any[],
  shop: [] as number[],
};

const setParentFilter = (filter: any, isSetted: boolean) => {
  emits("setFilter", filter, isSetted);
};
</script>
