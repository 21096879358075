import { createStoreConfig } from "./common";

const endpoints = {
    getItems: { method: "get", url: "/catalog_data/brands" },
};


export const useBrandsStore = createStoreConfig<Brand>(endpoints, "brand", {
    order_by: "brand_pk",
    in: "brand_pk__in",
    pk: "brand_pk",
    ilike: "patterns__pattern__ilike",
    sortOptions: { primarySortField: "brand_pk" }
});

export interface Brand {
    brand_pk: string;
    brand_name: string;
}
