<template>
  <DataTable
    :value="segments"
    :class="[
      segments.length == 0 ? 'empty-table' : '',
      isActiveFirst ? 'active-first' : '',
    ]"
    class="scroll-table main-table"
    stripedRows
    @sort="sortTable"
    removableSort
    scrollable
    :loading="isLoading"
    resizableColumns
    columnResizeMode="expand"
  >
    <Column field="number" header="№" style="width: 38px">
      <template #body="slotProps">
        {{ rowsCount + slotProps.index + 1 }}
      </template>
    </Column>
    <Column field="name" header="Название" sortable style="min-width: 120px">
      <template #body="slotProps">
        <RouterLink
          :to="{ name: 'segment', params: { id: slotProps.data.id } }"
        >
          {{ slotProps.data.name }}
        </RouterLink>
      </template>
    </Column>
    <Column
      field="ctime"
      header="Дата создания"
      sortable
      style="max-width: 153px"
    >
      <template #body="slotProps">
        {{ convertDateFromUTC(slotProps.data.ctime) }}
      </template>
    </Column>
    <Column field="cuser__lname" header="Автор" sortable>
      <template #body="slotProps">
        {{ getUserName(slotProps.data.cuser) }}
      </template>
    </Column>
    <Column field="rich" header="Охват">
      <template #body="slotProps">
        <span v-if="slotProps.data.rich">
          {{ formatNumber(slotProps.data.rich.rich) }}
        </span>
      </template>
    </Column>
    <Column header="Тип периода" style="max-width: 183px">
      <template #body="slotProps">
        {{ getPeriodType(slotProps.data.filter) }}
      </template>
    </Column>
    <Column field="period" header="Период" style="max-width: 183px">
      <template #body="slotProps">
        {{ getPeriod(slotProps.data.filter) }}
      </template>
    </Column>
    <Column field="brand" header="Бренд" style="max-width: 150px">
      <template #body="slotProps">
        <ListWithTooltips
          :slotProps="slotProps.data.brands"
          items="brands"
          item-key="brand_pk"
          item-value="brand_name"
        />
      </template>
    </Column>
    <Column field="category" header="Категория" style="max-width: 150px">
      <template #body="slotProps">
        <ListWithTooltips
          :slotProps="slotProps.data.categories"
          items="categories"
          item-key="id"
          item-value="full_name"
        />
      </template>
    </Column>
    <Column field="shop" header="Магазин" style="max-width: 150px">
      <template #body="slotProps">
        <ListWithTooltips
          :slotProps="slotProps.data.shops"
          items="shops"
          item-key="id"
          item-value="shop_name"
        />
      </template>
    </Column>
    <Column field="state" header="Статус">
      <template #body="slotProps">
        <svg
          :class="slotProps.data.state"
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <circle cx="4" cy="4" r="4" fill="#F3E7AB" />
        </svg>
        {{
          segmentsDictionariesStore.statesMap.get(slotProps.data.state)?.name
        }}
      </template>
    </Column>
    <Column
      field="mtime"
      header="Дата обновления"
      sortable
      style="max-width: 173px"
    >
      <template #body="slotProps">
        {{ convertDateFromUTC(slotProps.data.mtime) }}
      </template>
    </Column>
    <Column field="type" header="Тип сегмента">
      <template #body="slotProps">
        {{ segmentsDictionariesStore.typesMap.get(slotProps.data.type)?.name }}
      </template>
    </Column>
    <Column frozen alignFrozen="right">
      <template #body="slotProps">
        <div
          @click="(event) => toggle(slotProps.data.id, event)"
          aria-haspopup="true"
          aria-controls="overlay_menu"
          class="button-menu"
        >
          <span :data-segment="1" class="pi pi-ellipsis-v"></span>
        </div>
      </template>
    </Column>
  </DataTable>
  <Menu ref="menu" id="overlay_menu" :model="filteredItems" :popup="true" />
  <DeleteConfirmDialog
    :visible="confirmVisible"
    deleted-name="сегмент"
    :blocked="blockedDeleteButton"
    @close="confirmVisible = false"
    @delete="deleteSegment"
  />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Menu from "primevue/menu";
import DeleteConfirmDialog from "@/shared/dialogs/DeleteConfirmDialog.vue";
import ListWithTooltips from "./table/ListWithTooltips.vue";

import {
  convertDateFromUTC,
  getPeriodStr,
  getOperandTypeName,
} from "@/services/utils/DateParser";
import { getUserName } from "@/services/utils/Names";
import { formatNumber } from "@/services/utils/Strings";
import { SegmentFilter, Filters } from "@/services/segments/filters";
import { useSegmentsStore, Segment } from "@/store/segments/segments";
import { useSegmentsDictionariesStore } from "@/store/dictionaries/segments";
import { useRouter } from "vue-router";

const props = defineProps<{
  segments: Segment[];
  isArchiveEnabled: boolean;
  isActiveFirst: boolean;
  isLoading: boolean;
  rowsCount: number;
}>();
const emits = defineEmits(["sortTable", "updateTable"]);
const segmentsStore = useSegmentsStore();
const segmentsDictionariesStore = useSegmentsDictionariesStore();
const router = useRouter();

let modifiedId = ref(0);
const confirmVisible = ref(false);
const blockedDeleteButton = ref(false);
const menu = ref();
const items = ref([
  {
    label: "Архивировать",
    icon: "pi pi-inbox",
    archive: "no_archive",
    command: () => {
      segmentsStore.archiveSegment(modifiedId.value).then(() => {
        emits("updateTable");
      });
    },
  },
  {
    label: "Разархивировать",
    icon: "pi pi-inbox",
    archive: "archive",
    command: () => {
      segmentsStore.unArchiveSegment(modifiedId.value).then(() => {
        emits("updateTable");
      });
    },
  },
  {
    label: "Дублировать",
    icon: "pi pi-copy",
    archive: "no_archive",
    command: () => {
      segmentsStore.duplicatedSegmentId = modifiedId.value;
      router.push({ name: "segmentCreate" });
    },
  },
  {
    label: "Удалить",
    icon: "pi pi-trash",
    archive: "both",
    command: () => {
      confirmVisible.value = true;
    },
  },
]);

const filteredItems = computed(() => {
  return items.value.filter((item) =>
    props.isArchiveEnabled
      ? ["both", "archive"].includes(item.archive)
      : ["both", "no_archive"].includes(item.archive)
  );
});

function getPeriod(filter: Filters) {
  const segmentFilter = new SegmentFilter(filter);
  const operands = segmentFilter.findDateOperands();
  return getPeriodStr(operands);
}

function getPeriodType(filter: Filters) {
  const segmentFilter = new SegmentFilter(filter);
  const operands = segmentFilter.findDateOperands();
  return getOperandTypeName(operands);
}

const toggle = (segmentId: number, event: any) => {
  modifiedId.value = segmentId;
  menu.value.toggle(event);
};

const deleteSegment = () => {
  blockedDeleteButton.value = true;
  segmentsStore.deleteSegment(modifiedId.value).finally(() => {
    emits("updateTable");
    blockedDeleteButton.value = false;
    confirmVisible.value = false;
  });
};

const sortTable = (event: any) => {
  let orderField = "";
  if (event.sortField)
    orderField = event.sortOrder > 0 ? event.sortField : `-${event.sortField}`;
  emits("sortTable", orderField);
};
</script>

<style lang="scss" scoped>
.button-menu:hover {
  cursor: pointer;
}
</style>
