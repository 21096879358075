import { createStoreConfig } from "./common";

const endpoints = {
    getItems: { method: "get", url: "/sources?is_platform=true" },
};

export const usePlatformssStore = createStoreConfig<Platform>(endpoints, "platform", {
    order_by: "name",
    in: "slug",
    pk: "slug",
    ilike: "name__ilike",
    sortOptions: { primarySortField: "name" }
});

export interface Platform {
    slug: string,
    name: string,
    is_platform: string
}
