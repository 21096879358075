import { SortOptions } from "@/store/catalog_data/catalog_data";

export function moveItemToFront(array: any[], id: number): any[] {
    const index = array.findIndex((item: any) => item.id === id);

    if (index > -1) {
        const item = array.splice(index, 1)[0];
        array.unshift({ ...item });
    }

    return array;
}

export function sortArrayByStr(array: any[], field: string) {
    array.sort((a, b) => {
        if (a[field] < b[field]) {
            return 1;
        }
        if (a[field] > b[field]) {
            return -1;
        }
        return 0;
    });
}

export function sortArrayByFieldWithPrefered(
    items: any[],
    preferredOrder: string[],
    sortKey: string,
    sortOptions: SortOptions
): any[] {
    // Sort initially by the field alphabetically
    const sortedByField = dynamicSort(items, sortOptions)

    // Sort so that preferredOrder comes first
    return sortedByField.sort((a, b) => {
        const indexA = preferredOrder.map(String).indexOf(String(a[sortKey]));
        const indexB = preferredOrder.map(String).indexOf(String(b[sortKey]));

        if (indexA > -1 && indexB > -1) {
            return indexA - indexB;
        } else if (indexA > -1) {
            return -1;
        } else if (indexB > -1) {
            return 1;
        }
        return 0;
    });
}

function dynamicSort(items: any[], options: SortOptions) {
    const {
        primarySortField,
        primarySortOrder = "asc",
        secondarySortFields = [],
    } = options;

    return items.sort((a, b) => {
        const primaryComparison =
            String(a[primarySortField]).localeCompare(
                String(b[primarySortField]),
                undefined,
                { numeric: true }
            ) * (primarySortOrder === "asc" ? 1 : -1);

        if (primaryComparison !== 0) {
            return primaryComparison;
        }

        for (const { field, order = "asc" } of secondarySortFields) {
            const secondaryComparison =
                String(a[field]).localeCompare(String(b[field]), undefined, {
                    numeric: true,
                }) * (order === "asc" ? 1 : -1);

            if (secondaryComparison !== 0) {
                return secondaryComparison;
            }
        }

        return 0;
    });
}

export function mergeArrays(pk: string, ...arrays: any[][]): any[] {
    return arrays.reduce((acc: any[], currentArray: any[]) => {
        currentArray.forEach((currentItem: any) => {
            if (!acc.some((item: any) => item[pk] === currentItem[pk])) {
                acc.push(currentItem);
            }
        });
        return acc;
    }, []);
}

export function getName(e: any, names: string[] | undefined) {
    const e_names: string[] = [];
    if (names)
        names.forEach((elem) => {
            e_names.push(e[elem]);
        });
    return e_names.join(" ");
}
