import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import ListSegmentsPage from "../pages/segments/ListSegmentsPage.vue";
import ListCampaignsPage from "../pages/campaigns/ListCampaignsPage.vue";
import SegmentPage from "../pages/segments/SegmentPage.vue";
import CampaignPage from "@/pages/campaigns/CampaignPage.vue";

import LoginPage from "../pages/auth/LoginPage.vue";
import PrivacyPolicyPage from "../pages/auth/PrivacyPolicyPage.vue";
import PersonalDataPage from "../pages/auth/PersonalDataPage.vue";

import { useAuthStore } from "@/store/auth/login";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/segments"
  },
  {
    path: "/segments",
    name: "listSegments",
    component: ListSegmentsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/segments/:id",
    name: "segment",
    component: SegmentPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/segments/create",
    name: "segmentCreate",
    component: SegmentPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: PrivacyPolicyPage,
  },
  {
    path: "/personal-data-policy",
    name: "personalDataPolicy",
    component: PersonalDataPage,
  },
  {
    path: "/campaigns",
    name: "listCampaigns",
    component: ListCampaignsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaigns/:id",
    name: "campaign",
    component: CampaignPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaigns/create",
    name: "campaignCreate",
    component: CampaignPage,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const store = useAuthStore();

  if (to.name == "login" && (store.isLoggedIn || localStorage.getItem("access_token"))) {
    next(from);
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !(localStorage.getItem("access_token") || store.isLoggedIn)
  ) {
    next({ name: "login", query: { next: to.fullPath?.toString() } });
  } else {
    next();
  }
});

export default router;
