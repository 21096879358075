
import { defineStore } from "pinia";

export default defineStore('common', {
  state: () => {
    return {
      toastGlobalMessage: "",
      toastGlobalState: false,
      toastGlobalSeverity: "error",
      toastGlobalHead: "Ошибка"
    }
  },
  getters: {
  },
  actions: {
  },
})
