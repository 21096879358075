import { defineStore } from "pinia";
import { rootGetters } from "@/main";

const endpoints = {
    getCategoriesTree: { method: "get", url: "/catalog_data/categories/tree" },
    getCategories: { method: "get", url: "/catalog_data/categories" },
};

export const useCategoriesStore = defineStore("categories", {
    state: () => ({
        categories: [] as Category[],
        categoriesMap: new Map<number, CategoryMapItem>(),
        allCategories: [] as Category[],
    }),
    actions: {
        async getMapItems() {
            await rootGetters.api
                .proceedRequest(endpoints.getCategoriesTree, {})
                .then((response) => {
                    if (response.data)
                        response.data.forEach((element: any) => {
                            this.convertToMap(element);
                        });
                });
        },
        async getChoosenItems(choosedCategories: number[]) {
            return await rootGetters.api
                .proceedRequest(
                    endpoints.getCategoriesTree,
                    {},
                    { id__in: choosedCategories.join(",") }
                )
                .then((response) => {
                    if (response.data) {
                        this.allCategories = [...this.categories];
                        response.data.forEach((e: CategoryInApi) => {
                            this.replaceElement(this.convertCategoryFromJsonToRef(e));
                        });
                    }
                });
        },
        async getItems(name__ilike = "", parent_id = 0) {
            const params: CategoryParams = {};
            if (parent_id == 0) {
                if (name__ilike) {
                    params.name__ilike = name__ilike;
                } else {
                    params.level = "c1";
                }
                return await rootGetters.api
                    .proceedRequest(endpoints.getCategoriesTree, {}, params)
                    .then((response) => {
                        // новая переменная, чтобы не было пустого списка при поиске
                        const newCategories = [] as Category[];
                        if (response.data)
                            response.data.forEach((element: any) => {
                                newCategories.push(this.convertCategoryFromJsonToRef(element));
                            });
                        this.categories = [...newCategories];
                    });
            } else {
                params.parent_id = parent_id;
                params.per_page = 100;
                return await rootGetters.api
                    .proceedRequest(endpoints.getCategories, {}, params)
                    .then((response) => {
                        if (response.data) {
                            const category = this.findCategoryById(
                                this.categories,
                                parent_id
                            );
                            if (category) {
                                category.children = [];
                                response.data.forEach((element: any) => {
                                    category.children?.push(
                                        this.convertCategoryFromJsonToRef(element, category.key)
                                    );
                                });
                                return category;
                            }
                        }
                    });
            }
        },
        convertCategoryFromJsonToRef(
            category: any,
            parentKey = "",
            needAddition = true
        ): Category {
            const key = parentKey ? `${parentKey}-${category.id}` : `${category.id}`;

            if (!category.children) {
                category.children = [];
            }

            const children = category.children?.map((child: any) =>
                this.convertCategoryFromJsonToRef(child, key)
            );

            if (needAddition && ["c1", "c2", "c3"].includes(category.level) && (this.categoriesMap.get(category.id)?.countChild)) {
                children.push({
                    key: `${key}-0`,
                    label: "Другие варианты",
                    styleClass: "hidden",
                    data: 0,
                });
            }

            return {
                key: key,
                label: category.name,
                data: category.id,
                styleClass: `cat-${category.id}`,
                ...{ children: children },
            };
        },
        findCategoryById(
            categories: Category[],
            targetData: number
        ): Category | undefined {
            let result: Category | undefined;

            for (const category of categories) {
                if (category.data === targetData) {
                    return category;
                }

                if (category.children) {
                    result = this.findCategoryById(category.children, targetData);
                    if (result) {
                        return result;
                    }
                }
            }

            // Если категория с заданным значением `data` не найдена:
            return undefined;
        },
        convertToMap(category: CategoryInApi) {
            this.categoriesMap.set(category.id, {
                name: category.name,
                countChild: category.children ? category.children.length : 0,
            });
            category.children?.map((child: any) => this.convertToMap(child));
        },
        replaceElement(newEl: Category) {
            const index = this.allCategories.findIndex(
                (el) => el.data === newEl.data
            );
            if (index !== -1) {
                this.allCategories[index] = newEl;
            }
        },
    },
});

interface CategoryParams {
    name__ilike?: string;
    level?: string;
    per_page?: number;
    parent_id?: number;
}

interface Category {
    key: string;
    label: string;
    data: number;
    styleClass?: string;
    children?: Category[];
}

interface CategoryInApi {
    id: number;
    name: string;
    children?: CategoryInApi[];
}

export interface CategoryMapItem {
    name: string;
    countChild: number;
}
