<template>
  <Dialog
    v-model:visible="visibleLocal"
    @hide="$emit('close')"
    modal
    header="Архивирование"
    :style="{ width: '30rem' }"
  >
    <span
      >Вы уверены, что хотите архивировать {{ archivedName }}?<br />
      Вы сможете восстановить данные, если вы передумаете.</span
    >
    <div class="flex buttons">
      <Button
        type="button"
        label="Архивировать"
        class="danger"
        :disabled="blocked"
        @click="$emit('archive')"
      ></Button>
      <Button
        type="button"
        label="Отменить"
        severity="secondary"
        :disabled="blocked"
        @click="$emit('close')"
      ></Button>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

const props = defineProps<{
  visible: boolean;
  blocked: boolean;
  archivedName: string;
}>();

const visibleLocal = ref(props.visible);
watch(
  () => props.visible,
  (newValue) => {
    visibleLocal.value = newValue;
  }
);
</script>

<style lang="scss" scoped>
.p-button.danger {
  background: $danger-color;
  border: 1px solid $danger-color;
}

.buttons {
  margin-top: 20px;
  button {
    flex: 1;

    &:first-child {
      margin-right: 20px;
    }
  }
}
</style>
