<template>
  <TreeSelect
    :modelValue="props.modelValue"
    :options="
      isSegmentSetted ? categoryStore.allCategories : categoryStore.categories
    "
    class="categories"
    selectionMode="checkbox"
    placeholder="Любая категория"
    :panelClass="[isSegmentSetted ? 'hidden' : '']"
    @node-expand="nodeExpand"
    @show="beforeShow"
    @update:modelValue="
      ($event: any) => {
        emit('update:modelValue', $event);
      }
    "
  >
    <template #value>
      <div
        class="node-container"
        v-if="getNodes().value.length > 0 && !Array.isArray(props.modelValue)"
      >
        <div v-for="node in selectedNodes" :key="node.key" class="node">
          {{ node.label }}
        </div>
      </div>
      <div
        v-else-if="
          (Array.isArray(props.modelValue) && props.modelValue.length > 0) ||
          isLoading
        "
        class="any"
      >
        <i class="pi pi-spin pi-spinner"></i>
      </div>
      <div v-else class="any">Любая категория</div>
    </template>
    <template #header v-if="!isSegmentSetted">
      <div class="search-cont">
        <IconField iconPosition="left">
          <InputIcon
            :class="[isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-search']"
          >
          </InputIcon>
          <InputText v-model="filter" placeholder="Поиск по категориям" />
        </IconField>
      </div>
    </template>
  </TreeSelect>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";

import InputText from "primevue/inputtext";
import TreeSelect from "primevue/treeselect";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";

import { useCategoriesStore } from "@/store/catalog_data/categories";

const props = defineProps(["modelValue", "isSegmentSetted"]);
const emit = defineEmits(["update:modelValue"]);
const categoryStore = useCategoriesStore();

const filter = ref("");
const isLoading = ref(false);
const debounceTimeout = ref(null as number | null);
const selectedNodes = ref([] as any[]);

const sendItemRequest = () => {
  isLoading.value = true;
  categoryStore.getItems(filter.value).then(() => {
    isLoading.value = false;
  });
};

watch(
  () => filter.value,
  () => {
    if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
    debounceTimeout.value = setTimeout(sendItemRequest, 1000);
  }
);

onMounted(() => {
  loadCategories();
});

const loadChoosenValues = (dataToMatch: number[]) => {
  isLoading.value = true;
  categoryStore.getMapItems().then(() => {
    categoryStore.getItems().then(() => {
      if (Array.isArray(dataToMatch))
        categoryStore.getChoosenItems(dataToMatch).then(() => {
          emit("update:modelValue", createMap(dataToMatch));
          isLoading.value = false;
        });
    });
  });
};

const loadCategories = () => {
  isLoading.value = true;
  categoryStore.getMapItems().then(() => {
    categoryStore.getItems().then(() => {
      isLoading.value = false;
    });
  });
};

const getNodes = () => {
  selectedNodes.value = getSelectedNodes();
  return selectedNodes;
};

const beforeShow = () => {
  const element = document.querySelectorAll(
    ".p-tree-container .p-treenode[aria-selected='true'][aria-expanded='true']"
  );
  element.forEach((element) => {
    element.classList.add("scoped");
    element.addEventListener("click", () => {
      if (element && element.classList.contains("scoped")) {
        element.querySelectorAll(".p-treenode-content button")[0].click();
        element.classList.remove("scoped");
      }
    });
  });
};

const nodeExpand = (event: any) => {
  if (
    event.children &&
    event.children.length == 1 &&
    event.children[0].data == 0
  ) {
    isLoading.value = true;
    categoryStore.getItems("", event.data).then((category: any) => {
      isLoading.value = false;
      const m = { ...props.modelValue };

      const expanded = document.querySelectorAll(
        ".p-treenode[aria-expanded='true']"
      );

      const expandedClasses: Element[] = [];

      expanded.forEach((e) =>
        expandedClasses.push(e.childNodes[0].classList[1])
      );

      // костыль для сворачивания leaf
      if (category.key in m && m[category.key].checked) {
        category.children.forEach((element: any) => {
          m[element.key] = {
            checked: true,
            partialChecked: false,
          };
        });

        emit("update:modelValue", m);

        setTimeout(() => {
          const children = document.querySelectorAll(
            ".p-treenode[aria-expanded='true']"
          );
          children?.forEach((element: Element) => {
            const zasr = element.querySelectorAll(
              ".p-treenode-children .p-treenode"
            );

            const catClass = element.childNodes[0].classList[1];

            if (
              (zasr.length == 1 && zasr[0].ariaLabel == "Другие варианты") ||
              !expandedClasses.includes(catClass)
            ) {
              element.querySelectorAll(".p-treenode-content button")[0].click();
            }
          });
        }, 100);
      }
    });
  }
};

// get only selected for input
function getSelectedNodes() {
  const value = props.modelValue;
  const newNodes: any[] = [];
  Object.keys(value).forEach((key: string) => {
    const parentKeyParts = key.split("-");
    const child = parentKeyParts.pop();

    const parentKey = parentKeyParts.join("-");
    if (
      !(value[parentKey] && value[parentKey].checked) &&
      value[key].checked &&
      child != "0" &&
      child
    ) {
      newNodes.push({
        key: key,
        label: categoryStore.categoriesMap.get(parseInt(child))?.name,
      });
    }
  });

  return newNodes;
}

//map category to v-model
function createMap(dataToMatch: any[]) {
  const resultMap: any = {};

  function checkAndAddToMap(item: any, isParentMatched: boolean) {
    const isMatched = dataToMatch.includes(item.data);
    const hasChildren = Boolean(item.children && item.children.length > 0);
    let isChildMatched = false;

    if (hasChildren) {
      item.children.forEach((child: any) => {
        isChildMatched =
          checkAndAddToMap(child, isMatched || isParentMatched) ||
          isChildMatched;
      });
    }

    const partialMatched =
      !isMatched && hasChildren && !isParentMatched && isChildMatched;

    if (
      !item.key.endsWith("-0") &&
      (isMatched || isParentMatched || hasChildren)
    ) {
      const newItem = {
        checked: isMatched || isParentMatched,
        partialChecked: partialMatched,
      };
      if (newItem.checked || newItem.partialChecked) {
        resultMap[item.key] = newItem;
      }
    }

    return isMatched || partialMatched;
  }

  categoryStore.allCategories.forEach((state: any) => {
    if (dataToMatch) checkAndAddToMap(state, false);
  });

  return resultMap;
}

defineExpose({
  loadChoosenValues,
});
</script>

<style lang="scss" scoped>
.search-cont {
  padding: 10px 15px 3px 15px;

  .p-inputtext.p-component {
    width: 100%;
  }
}

.node-container {
  display: flex;
  margin-left: -5px;

  .node {
    padding: 4px 10px;
    background-color: #f1f5f9;
    border-radius: 4px;
    margin-right: 8px;
  }
}

.any {
  padding: 0.25rem 0;
  color: #64748b;
}
</style>
