import store from '@/store';

export default class ToastCreator {
    show(severity: string, head: string, message = "") {
        const commonStore = store()

        commonStore.toastGlobalMessage = message;
        commonStore.toastGlobalHead = head;
        commonStore.toastGlobalSeverity = severity;
        commonStore.toastGlobalState = !commonStore.toastGlobalState;
    }
}