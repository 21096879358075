<template>
  <ListItemsWithTableLayout>
    <template #body>
      <CampaignsFilterForm
        :isFilterEnabled="isFilterEnabled"
        @toggle-filter="isFilterEnabled = !isFilterEnabled"
        @set-filter="setFilter"
      />
      <TopCampaignsPanel
        :isArchiveEnabled="isArchiveEnabled"
        :isFilterEnabled="isFilterEnabled"
        :isFilterSetted="isFilterSetted"
        @toggle-archive="toggleArchive"
        @set-common-filter="setCommonFilter"
        @toggle-filter="isFilterEnabled = !isFilterEnabled"
      ></TopCampaignsPanel>
      <div
        id="table-container"
        class="table-container flex flex-col"
        :class="[
          filteredCampaigns && filteredCampaigns.length == 0
            ? 'empty'
            : 'filled',
        ]"
      >
        <CampaignsTable
          :campaigns="filteredCampaigns"
          :isArchiveEnabled="isArchiveEnabled"
          :isActiveFirst="activeFirstFromStore"
          :isLoading="isLoading"
          :rowsCount="rowsCount(46, 20) * tableDisplaySettings.first"
          @sort-table="sortTable"
          @update-table="updateCampaignsList"
        ></CampaignsTable>
        <EmptyTableMessage
          v-if="
            filteredCampaigns && filteredCampaigns.length == 0 && !isLoading
          "
        >
          <template #text>
            Создайте первую рекламную кампанию. <br />
            Для этого кликните на кнопку "Создать РК".
          </template>
        </EmptyTableMessage>
      </div>
      <Paginator
        v-model:first="tableDisplaySettings.first"
        @update:first="updateCampaignsList"
        :rows="1"
        class="s-paginator"
        :totalRecords="store.campaignsPagesCount"
        template="PrevPageLink CurrentPageReport NextPageLink"
        currentPageReportTemplate="{first} из {totalRecords}"
      />
    </template>
  </ListItemsWithTableLayout>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";

import Paginator from "primevue/paginator";
import TopCampaignsPanel from "@/entities/campaigns/panels/TopListCampaignsPanel.vue";
import CampaignsFilterForm from "@/entities/campaigns/forms/CampaignsFilterForm.vue";
import ListItemsWithTableLayout from "@/widgets/layouts/ListItemsWithTableLayout.vue";
import EmptyTableMessage from "@/entities/segments/EmptyTableMessage.vue";
import CampaignsTable from "@/entities/campaigns/CampaignsTable.vue";

import {
  useCampaignStore,
  Campaign,
  CampaignsParams,
} from "@/store/campaigns/campaigns";
import { useCampaignsDictionariesStore } from "@/store/dictionaries/campaigns";
import { FilterForm } from "@/services/campaigns/utils";
import { rowsCount } from "@/services/utils/Pagination";
import { setCampaignParamsByFilter } from "@/services/campaigns/utils";

const store = useCampaignStore();
const campaignsDictionariesStore = useCampaignsDictionariesStore();
const isArchiveEnabled = ref(false);
const isFilterEnabled = ref(false);
const isFilterSetted = ref(false);
const isLoading = ref(false);
const tableDisplaySettings = ref({
  first: 0,
  orderField: "",
  isArchived: false,
  filter: {} as FilterForm,
  commonFilter: "",
});

const filteredCampaigns = computed(() => {
  return store.campaignsList;
});

onMounted(() => {
  loadData();
});

const loadData = () => {
  isLoading.value = true;
  campaignsDictionariesStore.getStates().then(() => {
    tableDisplaySettings.value.stateIdIn =
      campaignsDictionariesStore.defaultStates;
    updateCampaignsList();
  });
  campaignsDictionariesStore.getSegmentsStates();
};

const activeFirstFromStore = computed(() => store.activeFirst);

const updateCampaignsList = () => {
  let isEnded = false;

  setTimeout(() => {
    if (!isEnded) {
      isLoading.value = true;
    }
  }, 200);

  const params: CampaignsParams = {
    page: tableDisplaySettings.value.first + 1,
    per_page: rowsCount(46, 20),
  };

  setCampaignParamsByFilter(params, tableDisplaySettings.value);

  store.getCampaigns(params).finally(() => {
    isLoading.value = false;
    isEnded = true;
  });
};
const toggleArchive = () => {
  isArchiveEnabled.value = !isArchiveEnabled.value;
  tableDisplaySettings.value.first = 0;
  if (isArchiveEnabled.value) tableDisplaySettings.value.isArchived = true;
  else tableDisplaySettings.value.isArchived = false;
  updateCampaignsList();
};

const setCommonFilter = (filter: string) => {
  tableDisplaySettings.value.commonFilter = filter;
  updateCampaignsList();
};

const setFilter = (filter: object, isSetted: boolean) => {
  tableDisplaySettings.value.filter = filter;
  isFilterSetted.value = isSetted;
  updateCampaignsList();
};

const sortTable = (order_by: string) => {
  tableDisplaySettings.value.orderField = order_by;
  updateCampaignsList();
};

let resizeTimeout: number;

window.addEventListener("resize", function () {
  clearTimeout(resizeTimeout);
  resizeTimeout = setTimeout(updateCampaignsList, 200);
});
</script>
