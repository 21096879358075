import { ConditionsGroup } from "@/services/segments/filters";
import { FilterForm, SegmentsParams } from "@/store/segments/segments";
import { formatDateToYYYYMMDD } from "../utils/DateParser";

export function hasModifiedConditions(
    conditionsGroups: ConditionsGroup[]
): boolean {
    return conditionsGroups.some((conditionGroup) => {
        const isPeriodStartModified = conditionGroup.period.start !== null || conditionGroup.flexible_period.start.interval != 0;
        // const isPeriodEndModified = conditionGroup.period.end !== null;

        const isCategoryModified = Object.keys(conditionGroup.category).length > 0;
        const isBrandModified = conditionGroup.brand.length > 0;
        const isShopModified = conditionGroup.shop.length > 0;

        return (
            isPeriodStartModified && (isCategoryModified || isBrandModified || isShopModified)
        );
    });
}

export function setParamsByFilter(
    params: SegmentsParams,
    settings: TableSettings
) {
    if (settings.stateId) {
        params.state_id = settings.stateId;
    } else {
        params.state_id__in = settings.stateIdIn;
    }

    if (settings.orderField) {
        params.order_by = settings.orderField;
    }

    if (Object.keys(settings.filter).length > 0) {
        if (settings.filter.name) {
            params.name__ilike = settings.filter.name;
        }

        if (settings.filter.state.length > 0) {
            params.state_id__in = settings.filter.state.join(",");
        }

        if (settings.filter.type.length == 1) {
            params.type_id = settings.filter.type[0];
        }

        if (settings.filter.brand.length > 0) {
            params.brands__brand_pk__in = settings.filter.brand.join(",");
        }

        if (settings.filter.shop.length > 0) {
            params.shops__shop_id__in = settings.filter.shop.join(",");
        }

        if (settings.filter.user.length > 0) {
            params.cuser__id__in = settings.filter.user.join(",");
        }

        if (Object.keys(settings.filter.category).length > 0) {
            params.categories__category_id__in = getCategories(
                settings.filter.category
            ).join(",");
        }

        if (settings.filter.date)
            params.cdate = formatDateToYYYYMMDD(settings.filter.date);

    }

    if (settings.commonFilter) {
        params.name__ilike = settings.commonFilter;
    }
}



function getCategories(category: any) {
    const values: number[] = [];
    for (const key in category) {
        const parentKeyParts = key.split("-");
        const catId = parentKeyParts.pop();
        const parentKey = parentKeyParts.join("-");

        if (
            catId != "0" &&
            category[key].checked &&
            !(category[parentKey] && category[parentKey].checked)
        ) {
            if (catId) values.push(parseInt(catId));
        }
    }

    return values;
}

interface TableSettings {
    filter: FilterForm;
    first: number;
    orderField: string;
    stateId: number;
    stateIdIn: string;
    commonFilter: string;
}
