import { ref, computed } from "vue";

import { useBrandsStore } from "@/store/catalog_data/brands";
import { useShopsStore } from "@/store/catalog_data/shops";
import { useUsersStore } from "@/store/auth/users";
import { useSegmentsDictionaryStore } from "@/store/segments/segments_dictionary";
import { usePlatformssStore } from "@/store/catalog_data/platforms";
import { useAdsCabinetsStore } from "@/store/catalog_data/ads_cabinets";

export function useCatalogData(selectType: string, catalogDataSettingsIn: CatalogDataSettings, useIdOnly: boolean) {
    let store = null as any;

    const catalogDataSettings = ref(catalogDataSettingsIn);

    function updateSettings(newValue: CatalogDataSettings) {
        catalogDataSettings.value = newValue;
    }

    switch (selectType) {
        case "brand":
            store = useBrandsStore();
            break;
        case "shop":
            store = useShopsStore();
            break;
        case "user":
            store = useUsersStore();
            break;
        case "segment":
            store = useSegmentsDictionaryStore();
            break;
        case "platform":
            store = usePlatformssStore();
            break;
        case "ads_cabinet":
            store = useAdsCabinetsStore();
            break;
    }

    const items = computed(() => {
        if (selectType == "user") return store["itemsWithName"];
        return store["itemList"];
    });

    const optionName = computed(() => {
        if (selectType == "shop") return { label: "shop_name", pk: "id" };
        if (selectType == "user" || selectType == "segment")
            return { label: "name", pk: "id" };
        if (selectType == "platform") return { label: "name", pk: "slug" };
        if (selectType == "ads_cabinet") return { label: "name", pk: "id" };
        return { label: selectType + "_name", pk: selectType + "_pk" };
    });

    const onFilterItems = (event: any) => {
        event.value != ""
            ? (catalogDataSettings.value.isLoading = true)
            : (catalogDataSettings.value.isLoading = false);
        catalogDataSettings.value.filterStr = event.value;
    };

    const getSortingArray = (newValue: any[]) => {
        if (newValue && newValue.length &&
            typeof newValue[0] == "object" &&
            newValue[0] != null) {
            return newValue.map((e) => e[optionName.value.pk]);
        }
        return newValue;
    };

    const replaceItems = (newValue: any[]) => {
        if (newValue.length && typeof newValue[0] != "object" && !useIdOnly) {
            const newItems = items.value.filter((item: any) =>
                newValue.includes(item[optionName.value.pk])
            );
            return newItems;
        }
        return [];
    };

    const objectToValue = (newValue: any) => {
        if (typeof newValue == "object") return newValue[optionName.value.pk];
        return newValue;
    }

    const addScrollHundler = (element: Element) => {
        element.scrollTop = 0;
        let isLazied = false;
        element.addEventListener("scroll", () => {
            if (element) {
                const atBottom = element.scrollHeight - element.scrollTop;

                if (element.children[0].childElementCount >= 50) {
                    if (
                        Math.abs(atBottom - element.clientHeight) < 150 &&
                        !catalogDataSettings.value.isLoadedAll
                    ) {
                        if (!isLazied) {
                            isLazied = true;
                            store
                                .getItems(catalogDataSettings.value.filterStr, [], false)
                                .then((size: number) => {
                                    if (!size) {
                                        catalogDataSettings.value.isLoadedAll = true;
                                    }
                                    catalogDataSettings.value.isLoading = false;
                                    isLazied = false;
                                });
                        }
                    }

                    if (
                        atBottom === element.clientHeight &&
                        isLazied &&
                        !catalogDataSettings.value.isLoadedAll
                    ) {
                        catalogDataSettings.value.isLoading = true;
                    }
                }
            }
        });
    };

    const sendItemRequest = (value: any[]) => {
        if (catalogDataSettings.value.filterStr != "")
            store
                .getItems(catalogDataSettings.value.filterStr, getSortingArray(value))
                .then(() => {
                    setTimeout(() => {
                        catalogDataSettings.value.isLoading = false;
                    }, 200);
                });
    };

    return {
        store,
        items,
        optionName,
        addScrollHundler,
        updateSettings,
        onFilterItems,
        getSortingArray,
        sendItemRequest,
        replaceItems,
        objectToValue
    }
}

interface CatalogDataSettings {
    isLoading: boolean,
    filterStr: string,
    debounceTimeout: number | null,
    isLoadingFirst: boolean,
    optionName: string,
    optionValue: string,
    isLoadedAll: boolean,
}