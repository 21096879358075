<template>
  <div
    class="top"
    id="top-hide-element"
    :class="[isFilterEnabled ? 'active' : '']"
  ></div>
  <div
    id="segments-filter-form"
    class="segments-filter-form scrollbar-custom"
    :class="[isFilterEnabled ? 'active' : '']"
  >
    <h2>Фильтры</h2>
    <slot name="fields" :filter="filter"></slot>
    <div class="flex flex-col fl-1 fl-jc-end">
      <div class="flex m-t-10">
        <Button
          label="Применить"
          severity="secondary"
          class="m-r-12 fl-1"
          @click="setFilter"
          :disabled="!isFilterChanged"
        />
        <Button
          label="Отменить"
          severity="secondary"
          class="fl-1"
          @click="cancelChanges"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";

import Button from "primevue/button";

import { deepEqual } from "@/services/utils/Objects";

const props = defineProps<{
  isFilterEnabled: boolean;
  initialFilter: any;
}>();
const emit = defineEmits(["toggleFilter", "setFilter"]);

const filter = ref(JSON.parse(JSON.stringify(props.initialFilter)));

const isFilterChanged = computed(() => {
  return !deepEqual(filter.value, props.initialFilter);
});

const cancelChanges = () => {
  emit("toggleFilter");
  filter.value = { ...props.initialFilter };
  emit("setFilter", filter.value, false);
};

const setFilter = () => {
  emit("toggleFilter");
  emit("setFilter", filter.value, true);
};

watch(
  () => props.isFilterEnabled,
  (newValue) => {
    if (newValue) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
  }
);

function handleClickOutside(event: any) {
  if (event.target.id == "top-hide-element") {
    emit("toggleFilter");
  }
}
</script>

<style lang="scss" scoped>
.segments-filter-form {
  position: fixed;
  top: 132px;
  right: 12px;
  bottom: 12px;

  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  flex-direction: column;

  width: 406px;
  min-height: 360px;
  overflow-y: auto;

  border-radius: 8px;
  border: 1px solid #d0d0d6;
  background: #fff;
  box-shadow: 0px 4px 13.6px 0px rgba(0, 0, 0, 0.19);

  padding: 12px;

  z-index: 1000;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  h2 {
    font-size: 1.14rem;
    line-height: 1.43rem;
    font-weight: 500;

    margin: 0;
    padding: 4px;
  }

  :slotted(label.small-label) {
    padding: 4px 4px 2px 4px;
    color: #a19cab;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
}

:slotted(.type-checkbox) {
  margin-bottom: 7px;
}

:deep(.p-inputtext) {
  width: 100%;
}
</style>
