import { CategoryMapItem } from "@/store/catalog_data/categories";
import { Filters, Operand, Filter } from "../segments/filters";

export function deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;

    if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

export function getFilterWithCategories(filter: Filters, categoryMap: Map<number, CategoryMapItem>) {
    if (filter.filters_for_db)
        processFilter(filter.filters_for_db, categoryMap)
    return filter;
}

function isCategoryOperand(obj: any): obj is Operand {
    return (obj as Operand).type !== undefined &&
        ["c1", "c2", "c3", "c4"].includes((obj as Operand).type);
}

function processFilter(filter: Filter, categoryMap: Map<number, CategoryMapItem>): void {
    for (const item of filter.operands) {
        if (isCategoryOperand(item)) {
            item.value = item.value.map((e: any) => {
                if (isStringNumber(e)) {
                    return categoryMap.get(parseInt(e))?.name;
                }
                return e;
            });
        }
        else {
            if ("operands" in item)
                processFilter(item, categoryMap);
        }
    }
}

export function objectToString(obj: Record<string, any>): string {
    return '[' + Object.entries(obj)
        .map(([key, value]) => `${key};${value}`)
        .join(',') + ']';
}

export function parseStringToObject(input: string): Record<string, any> {
    // Удаляем скобки в начале и конце строки
    const cleanedInput = input.slice(1, -1);
    const pairs = cleanedInput.split(',');
    const result: Record<string, any> = {};
    pairs.forEach(pair => {
        const [key, value] = pair.split(';');
        if (key && value) {
            result[key.trim()] = parseStrToInt(value.trim());
        }
    });

    return result;
}

function parseStrToInt(e: string) {
    if (Number.isInteger(+e)) return parseInt(e);
    return e;
}

function isStringNumber(value: string): boolean {
    return !isNaN(Number(value));
}