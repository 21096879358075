<template>
  <InnerPageLayout>
    <TopCampaignPanel
      :isLoading="isLoading"
      @create-campaign="createCampaign"
    ></TopCampaignPanel>
    <div class="form-container">
      <CampaignCreateForm
        :isLoading="isLoading"
        @set-loading="setLoading"
        ref="createFormRef"
      ></CampaignCreateForm>
    </div>
  </InnerPageLayout>
</template>

<script setup lang="ts">
import { ref } from "vue";
import InnerPageLayout from "@/widgets/layouts/InnerPageLayout.vue";
import TopCampaignPanel from "@/entities/campaigns/panels/TopCampaignPanel.vue";
import CampaignCreateForm from "@/entities/campaigns/forms/CampaignCreateForm.vue";

const isLoading = ref(false);
const createFormRef = ref(null);

const createCampaign = () => {
  if (createFormRef.value) createFormRef.value.createCampaign();
};

const setLoading = (value: boolean) => {
  isLoading.value = value;
};
</script>

<style lang="scss" scoped>
.form-container {
  position: relative;
  top: 77px;
  width: calc(100% - 316px);
}
</style>
