import { defineStore } from "pinia";
import { rootGetters } from "@/main";
import { Keys } from "./catalog_data";
import { Endpoints } from "./catalog_data";
import { sortArrayByFieldWithPrefered, mergeArrays, getName } from "@/services/utils/Arrays";

const perPageDefault = 50;

export function createStoreConfig<T>(endpoints: Endpoints, entity: string, keys: Keys) {
    return defineStore(entity + "s", {
        state: () => ({
            itemList: [] as T[],
            page: 0,
            pageWithFilter: 0,
            filter: "",
            count: 1,
        }),
        getters: {
            itemsWithName: (state) => {
                if (!("nameKeys" in keys)) return state.itemList;
                return state.itemList.map(e => ({
                    ...e,
                    name: getName(e, keys["nameKeys"])
                }));
            },
        },
        actions: {
            async getItems(
                pattern__ilike = "",
                preferredOrder: string[] = [],
                need_sort = true,
                get_preferred = false,
                page = 0,
                per_page = 50,
            ) {
                if (pattern__ilike != this.filter) {
                    this.filter = pattern__ilike;
                    this.pageWithFilter = 0;
                }

                const params: any = {
                    page: this.page + 1,
                    per_page: per_page,
                    order_by: keys.order_by
                };

                if (page) {
                    params[keys.in] = preferredOrder.join(",");
                    params.page = page;
                }

                if (pattern__ilike) {
                    params[keys.ilike] = pattern__ilike;
                    params.page = this.pageWithFilter + 1;
                }

                console.log(params);

                return await rootGetters.api.proceedRequest(endpoints.getItems, {}, params)
                    .then(async (response) => {
                        if (response.data) {
                            if (response.data.length)
                                pattern__ilike ? this.pageWithFilter++ : this.page++;

                            this.itemList = mergeArrays(keys.pk, this.itemList, response.data);

                            if (get_preferred && preferredOrder) {
                                this.count = 1;
                                await this.getPreferred(preferredOrder);
                            }
                            if (need_sort)
                                this.sortItems(preferredOrder);

                            return response.data.length;
                        }
                    });

            },
            sortItems(preferredOrder: string[]) {
                this.itemList = sortArrayByFieldWithPrefered(
                    this.itemList,
                    preferredOrder,
                    keys.pk,
                    keys.sortOptions
                );
            },
            async getPreferred(preferredOrder: string[]) {
                while (this.count > 0) {
                    const p = await this.getItems("", preferredOrder, false, false, this.count);
                    this.count = p && p >= perPageDefault ? this.count + 1 : 0;
                }
            }
        }
    });
}