import { Period, FlexiblePeriod, FlexiblePeriodItem } from "../segments/filters";

export function isMoreThanMonthInPeriod(date1: Date, date2: Date): boolean {
    return getDaysBetweenDates(date1, date2) > 31;
}

function getDaysBetweenDates(date1: Date, date2: Date): number {
    const oneDay = 24 * 60 * 60 * 1000; // количество миллисекунд в одном дне
    const diffTime = Math.abs(date2.getTime() - date1.getTime()); // разница во времени между датами
    const diffDays = Math.ceil(diffTime / oneDay); // округление до ближайшего целого количества дней
    return diffDays;
}

export function dayWeekBefore(): Date {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    return currentDate;
}

export function isValidPeriod(period: Period): boolean {
    if (period.start == null && period.end != null) return false;
    else if (period.start == null || period.end == null) return true;
    return period.start.getTime() < period.end.getTime();
}

export function isValidFlexiblePeriod(flexiblePeriod: FlexiblePeriod) {
    const now = new Date();
    const startDate = addPeriodToDate(now, flexiblePeriod.start);
    const endDate = addPeriodToDate(now, flexiblePeriod.end);

    return startDate > endDate;
}

function addPeriodToDate(date: Date, period: FlexiblePeriodItem): Date {
    const newDate = new Date(date);
    switch (period.unit) {
        case "DAY":
            newDate.setDate(newDate.getDate() + period.interval);
            break;
        case "WEEK":
            newDate.setDate(newDate.getDate() + period.interval * 7);
            break;
        case "MONTH":
            newDate.setMonth(newDate.getMonth() + period.interval);
            break;
        case "YEAR":
            newDate.setFullYear(newDate.getFullYear() + period.interval);
            break;
        default:
            throw new Error("Invalid unit");
    }
    return newDate;
}
