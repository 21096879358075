<template>
  <ListItemsWithTableLayout>
    <template #body>
      <SegmentFilterForm
        :isFilterEnabled="isFilterEnabled"
        @toggle-filter="isFilterEnabled = !isFilterEnabled"
        @set-filter="setFilter"
      />
      <TopSegmentsPanel
        :isArchiveEnabled="isArchiveEnabled"
        :isFilterEnabled="isFilterEnabled"
        :isFilterSetted="isFilterSetted"
        @toggle-archive="toggleArchive"
        @set-common-filter="setCommonFilter"
        @toggle-filter="isFilterEnabled = !isFilterEnabled"
      ></TopSegmentsPanel>
      <div
        id="table-container"
        class="table-container flex flex-col"
        :class="[filteredSegments.length == 0 ? 'empty' : 'filled']"
      >
        <SegmentsTable
          :segments="filteredSegments"
          :isArchiveEnabled="isArchiveEnabled"
          :isActiveFirst="activeFirstFromStore"
          :isLoading="isLoading"
          :rowsCount="rowsCount(43) * tableDisplaySettings.first"
          @sort-table="sortTable"
          @update-table="updateSegmentsList"
        />
        <EmptyTableMessage v-if="filteredSegments.length == 0 && !isLoading" />
      </div>
      <Paginator
        v-model:first="tableDisplaySettings.first"
        @update:first="updatePaginator"
        :rows="1"
        class="s-paginator"
        :totalRecords="segmentsStore.segmentsPagesCount"
        template="PrevPageLink CurrentPageReport NextPageLink"
        currentPageReportTemplate="{first} из {totalRecords}"
      />
    </template>
  </ListItemsWithTableLayout>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";

import {
  useSegmentsStore,
  SegmentsParams,
  FilterForm,
} from "@/store/segments/segments";
import { storeToRefs } from "pinia";
import { useSegmentsDictionariesStore } from "@/store/dictionaries/segments";
import { setParamsByFilter } from "@/services/segments/utils";
import { rowsCount } from "@/services/utils/Pagination";
import ListItemsWithTableLayout from "@/widgets/layouts/ListItemsWithTableLayout.vue";
import TopSegmentsPanel from "@/entities/segments/panels/TopListSegmentsPanel.vue";
import SegmentsTable from "@/entities/segments/SegmentsTable.vue";
import EmptyTableMessage from "@/entities/segments/EmptyTableMessage.vue";
import SegmentFilterForm from "@/entities/segments/forms/SegmentsFilterForm.vue";
import Paginator from "primevue/paginator";

const segmentsStore = useSegmentsStore();
const segmentsDictionariesStore = useSegmentsDictionariesStore();
const { segments } = storeToRefs(segmentsStore);
const isArchiveEnabled = ref(false);
const isFilterEnabled = ref(false);
const isLoading = ref(false);
const tableDisplaySettings = ref({
  first: 0,
  orderField: "",
  stateId: 0,
  stateIdIn: "",
  filter: {} as FilterForm,
  commonFilter: "",
});
const isFilterSetted = ref(false);

const filteredSegments = computed(() => {
  if (!segments.value) return [];
  return segments.value;
});

onMounted(() => {
  loadData();
});

const loadData = () => {
  isLoading.value = true;
  segmentsDictionariesStore.getStates().then(() => {
    tableDisplaySettings.value.stateIdIn =
      segmentsDictionariesStore.defaultStates;
    updateSegmentsList();
  });
  segmentsDictionariesStore.getTypes();
};

const activeFirstFromStore = computed(() => segmentsStore.activeFirst);

const updatePaginator = () => {
  updateSegmentsList();
};

const updateSegmentsList = () => {
  let isEnded = false;

  setTimeout(() => {
    if (!isEnded) {
      isLoading.value = true;
    }
  }, 200);

  const params: SegmentsParams = {
    page: tableDisplaySettings.value.first + 1,
    per_page: rowsCount(43),
  };

  setParamsByFilter(params, tableDisplaySettings.value);

  segmentsStore.getSegments(params).finally(() => {
    isLoading.value = false;
    isEnded = true;
  });
};

const toggleArchive = () => {
  isArchiveEnabled.value = !isArchiveEnabled.value;
  tableDisplaySettings.value.first = 0;
  if (isArchiveEnabled.value)
    tableDisplaySettings.value.stateId =
      segmentsDictionariesStore.statesMap.get("archived")?.id || 0;
  else tableDisplaySettings.value.stateId = 0;
  updateSegmentsList();
};

const sortTable = (order_by: string) => {
  tableDisplaySettings.value.orderField = order_by;
  updateSegmentsList();
};

const setFilter = (filter: object, isSetted: boolean) => {
  tableDisplaySettings.value.filter = filter;
  isFilterSetted.value = isSetted;
  updateSegmentsList();
};

const setCommonFilter = (filter: string) => {
  tableDisplaySettings.value.commonFilter = filter;
  updateSegmentsList();
};

let resizeTimeout: number;

window.addEventListener("resize", function () {
  clearTimeout(resizeTimeout);
  resizeTimeout = setTimeout(updateSegmentsList, 200);
});
</script>
