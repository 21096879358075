<template>
    <div class="text-data">
        <a @click="goBack()">Предыдущий шаг</a>
        <slot></slot>
        <Button label="Принять и продолжить" class="form-button" @click="goBack()"/>
    </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'

import Button from 'primevue/button';

const router = useRouter()

function goBack() {
    router.go(-1);
}
</script>

<style scoped lang="scss">
.text-data {
    background-color: white;
    border-radius: 20px;
    padding: 20px;

    a {
        color: rgb(136, 117, 181);
        font-size: 0.85em;
        font-weight: 500;
        line-height: 150%;

        &:hover {
            cursor: pointer;
        }
    }

    :slotted(h1) {
        margin: 20px 0;
    }

    :slotted(.text) {
        font-size: 1rem;
        font-weight: 400;
        line-height: 150%;
    }

    :slotted(.text>ol>li.top-section) {
        font-weight: 700;
        padding-bottom: 25px;
    }

    :slotted(.text>ol) {
        counter-reset: section;
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;

        &>li::before {
            counter-increment: section;
            content: counter(section) ". ";
        }
    }

    :slotted(.text>ol>li>ol) {
        counter-reset: subsection;
        list-style-type: none;
        font-weight: 400;
        margin-top: 3px;
        padding-left: 20px;

        &>li {
            display: block;
        }

        &>li::before {
            counter-increment: subsection;
            content: counter(section) "." counter(subsection) ". ";
        }
    }

    :slotted(.text ul) {
        list-style-type: none;
        padding-left: 15px;

        li:before {
            content: "—";
            position: relative;
            left: -5px;
        }
    }

    :slotted(.inline) {
        margin-top: 0;
    }

    .form-button {
        margin-top: 10px;
    }
}
</style>