<template>
  <div class="left-sidebar">
    <ul class="list-items" role="menu">
      <li v-for="route in routes" :key="route.name">
        <RouterLink
          :to="{ name: route.name }"
          :class="[route.active ? 'active' : '']"
        >
          <span :class="['pi', route.icon]"></span>
          <div>{{ route.title }}</div>
        </RouterLink>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

import { useRoute } from "vue-router";

const route = useRoute();

onMounted(() => {
  routes.value = routes.value.map((routeInSidebar) => ({
    ...routeInSidebar,
    active: route.name === routeInSidebar.name,
  }));
});

const routes = ref([
  {
    name: "listSegments",
    title: "Сегменты",
    icon: "pi-database",
    active: false,
  },
  {
    name: "listCampaigns",
    title: "Кампании",
    icon: "pi-briefcase",
    active: false,
  },
]);
</script>

<style lang="scss" scoped>
.left-sidebar {
  position: fixed;
  top: 72px;
  bottom: 12px;
  left: 12px;

  width: 220px;

  background-color: white;
  border-radius: 8px;

  padding: 8px 10px;
  z-index: 1000;

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;

      span {
        padding-right: 8px;
      }

      a {
        display: flex;
        align-items: center;
        height: 40px;

        color: $main-font-color;
        border-radius: 6px;
        font-size: 1rem;

        padding: 8px 16px;
        margin-bottom: 10px;

        &.active {
          background-color: #f1eff3;
        }

        &:hover {
          background-color: #f1eff3;
          color: $main-button-color;
        }
      }
    }
  }
}
</style>
