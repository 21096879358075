<template>
  <InnerPageLayout>
    <TopSegmentPanel
      :isCreateButtonEnabled="isCreateButtonEnabled"
      :isLoading="isLoading"
      @create-segment="createSegment"
    ></TopSegmentPanel>
    <div class="form-filter-container flex">
      <SegmentCreateForm
        :isLoading="isLoading"
        :audienceCount="audienceCount"
        @set-create-button="setCreateButton"
        @set-loading="setLoading"
        @set-filter="updateFilter"
        @update-is-ready-to-count="updateIsReadyToCount"
        @updateAudienceCount="updateAudienceCount"
        ref="createFormRef"
      ></SegmentCreateForm>
      <div class="aud-counter">
        <AudienceCounter
          :filter="filter"
          :setted-count="audienceCount"
          :is-ready-to-count="isReadyToCount"
          @updateAudienceCount="updateAudienceCount"
        ></AudienceCounter>
      </div>
    </div>
  </InnerPageLayout>
</template>

<script setup lang="ts">
import { ref } from "vue";

import InnerPageLayout from "@/widgets/layouts/InnerPageLayout.vue";
import TopSegmentPanel from "@/entities/segments/panels/TopSegmentPanel.vue";
import SegmentCreateForm from "@/entities/segments/forms/SegmentCreateForm.vue";
import AudienceCounter from "@/widgets/counters/AudienceCounter.vue";

const isCreateButtonEnabled = ref(false);
const isLoading = ref(false);
const filter = ref(null);
const createFormRef = ref(null);
const audienceCount = ref(0);
const isReadyToCount = ref(false);

const setCreateButton = (value: any) => {
  isCreateButtonEnabled.value = value.value;
};

const setLoading = (value: boolean) => {
  isLoading.value = value;
};

const updateFilter = (formFilter: any) => {
  filter.value = formFilter;
};

const createSegment = () => {
  if (createFormRef.value) createFormRef.value.createSegment();
};

const updateIsReadyToCount = (value: boolean) => {
  isReadyToCount.value = value;
};

const updateAudienceCount = (count: number) => {
  audienceCount.value = count;
};
</script>

<style lang="scss">
.form-filter-container {
  position: relative;
  top: 77px;
}

.aud-counter {
  margin-left: 30px;
  margin-right: 36px;
  flex-grow: 0;
}
</style>
