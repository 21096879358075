export function getUserName(user: User) {
    const fname = user.fname.length > 0 ? user.fname[0] + '.' : ''
    return `${user.lname} ${fname}`
}

interface User {
    id: number
    email: string
    is_active: boolean
    is_superuser: boolean
    is_verified: boolean
    fname: string
    lname: string
}