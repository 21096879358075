import { createStoreConfig } from "./common";

const endpoints = {
    getItems: { method: "get", url: "/catalog_data/shops" },
};

export const useShopsStore = createStoreConfig<Shop>(endpoints, "shop", {
    order_by: "shop_name",
    in: "id__in",
    pk: "id",
    ilike: "shop_name__ilike",
    sortOptions: { primarySortField: "shop_name" }
})

export interface Shop {
    id: number;
    shop_pk: string;
    shop_name: string;
    shop_short_name: string | null,
    shop_category: string | null,
    shop_inn: string
}