import { defineStore } from "pinia";
import { rootGetters } from "@/main";
import { moveItemToFront } from "@/services/utils/Arrays"
import { Brand } from "@/store/catalog_data/brands"

const endpoints = {
    getSegments: { method: "get", url: "/segments" },
    getSegment: { method: "get", url: "/segments/{segment_id}" },
    archiveSegment: { method: "put", url: "/segments/{segment_id}/archive" },
    unArchiveSegment: { method: "put", url: "/segments/{segment_id}/unarchive" },
    deleteSegment: { method: "delete", url: "/segments/{segment_id}" },
    createSegment: { method: "post", url: "/segments/create" },
    getAudience: { method: "post", url: "/segments/audience_filter" },
};

export const useSegmentsStore = defineStore("segments", {
    state: () => ({
        segmentsList: [] as Segment[],
        segmentsCount: 0,
        segmentsPerPage: 1,
        activeFirst: false,
        addedSegmentId: 0,
        duplicatedSegmentId: 0,
    }),
    getters: {
        segments: (state) => state.segmentsList,
        segmentsPagesCount: (state) => {
            return Math.ceil(state.segmentsCount / state.segmentsPerPage)
        }
    },
    actions: {
        async getSegments(params: SegmentsParams = { page: 1, per_page: 20 }) {
            await rootGetters.api.proceedRequest(
                endpoints.getSegments, {}, params
            ).then((response) => {
                this.segmentsList = response.data;
                if (this.segmentsList) {
                    this.segmentsPerPage = parseInt(response.headers["x-per-page"]);
                    this.segmentsCount = parseInt(response.headers["x-total-count"]);
                    // sortArrayByStr(this.segmentsList, "mtime");
                    if (this.addedSegmentId > 0) {
                        moveItemToFront(this.segmentsList, this.addedSegmentId);
                        this.addedSegmentId = 0;
                    }
                }
            });
        },

        async getSegment(segment_id: number) {
            return await rootGetters.api.proceedRequest(
                endpoints.getSegment, {
                segment_id: segment_id,
            }
            ).then((response) => {
                return response.data;
            });
        },
        async archiveSegment(segment_id: number) {
            await rootGetters.api
                .proceedRequest(endpoints.archiveSegment, {
                    segment_id: segment_id,
                })
        },
        async unArchiveSegment(segment_id: number) {
            await rootGetters.api
                .proceedRequest(endpoints.unArchiveSegment, {
                    segment_id: segment_id,
                })
        },
        async deleteSegment(segment_id: number) {
            await rootGetters.api
                .proceedRequest(endpoints.deleteSegment, {
                    segment_id: segment_id,
                })
        },
        async createSegment(segment: any) {
            await rootGetters.api
                .proceedRequest(endpoints.createSegment, {}, {}, segment)
                .then((response) => {
                    if (response) {
                        this.addedSegmentId = response.data.id;
                        this.activeFirst = true;

                        rootGetters.toast.show(
                            "info",
                            "Создание",
                            `"${response.data.name}" успешно сохранен в раздел "Список сегментов".`
                        );
                    }
                });
        },
        async getAudienceFilter(filter: any): Promise<number> {
            const response = await rootGetters.api
                .proceedRequest(endpoints.getAudience, {}, {}, filter);
            return response.data.count;
        }
    },
});

export interface Segment {
    id: number;
    name: string;
    filter: object;
    type: string;
    state: string;
    ctime: string;
    mtime: string;
    cuser_id: number;
    muser_id: number;
    brands: Brand[];
    categories: Category[];
    shops: Shop[];
}

interface Category {
    id: number;
    name: string;
    full_name: string;
    level: string;
}

interface Shop {
    shop_category: string;
    shop_inn: string;
    shop_name: string;
    shop_pk: string;
    shop_short_name: string;
}

export interface SegmentsParams {
    page: number;
    per_page: number;
    state_id?: number;
    state_id__in?: string;
    order_by?: string;
    name__ilike?: string;
    type_id?: number;
    cuser__id__in?: string;
    cdate?: string;
    brands__brand_pk__in?: string;
    categories__category_id__in?: string;
    shops__shop_id__in?: string;
}

export interface FilterForm {
    name: string;
    date: Date | null,
    state: number[],
    type: number[],
    user: number[],
    brand: string[],
    category: any[],
    shop: number[],
}
