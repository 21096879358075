<template>
  <IconField iconPosition="left">
    <InputIcon class="pi pi-search"> </InputIcon>
    <InputText
      :modelValue="props.modelValue"
      :placeholder="placeholder"
      @update:modelValue="emit('update:modelValue', $event)"
    />
  </IconField>
</template>

<script setup lang="ts">
import { ref } from "vue";

import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps(["modelValue", "placeholder"]);
</script>

<style lang="scss" scoped>
:deep(.p-inputtext) {
  width: 100%;
}
</style>
