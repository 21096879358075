import { defineStore } from "pinia";
import { rootGetters } from "@/main";

import { Brand } from "@/store/catalog_data/brands";
import { Shop } from "@/store/catalog_data/shops";
import { Platform } from "@/store/catalog_data/platforms"
import { AdsCabinet } from "../catalog_data/ads_cabinets";
import { Segment } from "../segments/segments";
import { moveItemToFront } from "@/services/utils/Arrays"

const endpoints = {
    getCampaigns: { method: "get", url: "/campaigns" },
    getCampaign: { method: "get", url: "/campaigns/{campaign_id}" },
    archiveCampaign: { method: "put", url: "/campaigns/{campaign_id}/archive" },
    unArchiveCampaign: {
        method: "put",
        url: "/campaigns/{campaign_id}/unarchive",
    },
    deleteCampaign: { method: "delete", url: "/campaigns/{campaign_id}" },
    createCampaign: { method: "post", url: "/campaigns" },
    updateCampaign: { method: "put", url: "/campaigns/{campaign_id}" },
};

export const useCampaignStore = defineStore("campaigns", {
    state: () => ({
        campaignsList: [] as Campaign[],
        campaignsCount: 0,
        duplicateCampaignId: 0,
        campaingsPerPage: 1,
        activeFirst: false,
        addedCampaignId: 0,
    }),
    getters: {
        campaigns: (state) => state.campaignsList,
        campaignsPagesCount: (state) => {
            return Math.ceil(state.campaignsCount / state.campaingsPerPage)
        }
    },
    actions: {
        async getCampaigns(params: CampaignsParams = { page: 1, per_page: 20 }) {
            await rootGetters.api.proceedRequest(
                endpoints.getCampaigns, {}, params
            ).then((response) => {
                this.campaignsList = response.data;
                if (this.campaignsList) {
                    this.campaingsPerPage = parseInt(response.headers["x-per-page"]);
                    this.campaignsCount = parseInt(response.headers["x-total-count"]);

                    if (this.addedCampaignId > 0) {
                        console.log(this.addedCampaignId)
                        moveItemToFront(this.campaignsList, this.addedCampaignId);
                        this.addedCampaignId = 0;
                    }
                }
            });
        },
        async getCampaign(campaignId: number) {
            return await rootGetters.api
                .proceedRequest(endpoints.getCampaign, { campaign_id: campaignId })
                .then((response) => {
                    return response.data;
                });
        },
        async getCampaignByName(name: string) {
            const response = await rootGetters.api.proceedRequest(
                endpoints.getCampaigns, {}, { name: name }
            );

            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                return response.data[0].id;
            }

            return null;
        },
        async createCampaign(campaign: ServerCampaignForCreate) {
            await rootGetters.api
                .proceedRequest(endpoints.createCampaign, {}, {}, campaign)
                .then((response) => {
                    if (response) {
                        this.addedCampaignId = response.data.id;
                        this.activeFirst = true;

                        rootGetters.toast.show(
                            "info",
                            "Создание",
                            `РК "${response.data.name}" успешно сохранена в раздел "Список кампаний".`
                        );
                    }
                }).catch((err) => {
                    return Promise.reject(err);
                })
        },
        async updateCampaign(campaign: ServerCampaignForCreate, campaign_id: number) {
            await rootGetters.api
                .proceedRequest(endpoints.updateCampaign, { campaign_id: campaign_id }, {}, campaign)
                .then((response) => {
                    if (response) {
                        this.addedCampaignId = response.data.id;
                        this.activeFirst = true;

                        rootGetters.toast.show(
                            "info",
                            "Обновление",
                            `РК "${response.data.name}" успешно обновлена.`
                        );
                    }
                }).catch((err) => {
                    return Promise.reject(err);
                })
        },
        async archiveCampaign(campaign_id: number) {
            await rootGetters.api
                .proceedRequest(endpoints.archiveCampaign, {
                    campaign_id: campaign_id,
                })
        },
        async unArchiveCampaign(campaign_id: number) {
            await rootGetters.api
                .proceedRequest(endpoints.unArchiveCampaign, {
                    campaign_id: campaign_id,
                })
        },
        async deleteCampaign(campaign_id: number) {
            await rootGetters.api
                .proceedRequest(endpoints.deleteCampaign, {
                    campaign_id: campaign_id,
                }).catch((err) => {
                    return Promise.reject(err);
                })
        },
    },
});


export interface Campaign {
    brand: Brand | null;
    shop: Shop | null;
    name: string;
    strategy: string;
    extra_info: string;
    date_from: null | Date;
    date_to: null | Date;
    campaign_segments: CampaignSegment[];
    id?: number;
    period?: string;
    ctime?: string;
    mtime?: string;
    state?: string;
    cuser?: object;
    muser_id?: number;
    cuser_id?: number;
}

export interface CampaignSegment {
    segment_id: number | null;
    ads_cabinet_id: number | null;
    platform_slug: string | null;
    is_segment_updatable: boolean;
    state?: string;
    name?: string;
    rich?: number;
    ctime?: string;
    mtime?: string;
}

export interface ServerCampaignForCreate {
    campaign_segments: CampaignSegment[];
    campaign: ServerCampaignNameForCreate;
}

export interface ServerCampaign {
    id: number,
    brand: Brand,
    shop: Shop,
    campaign_segments: ServerCampaignSegment[],
    start_date: string,
    end_date: string,
    extra_info: string,
    name: string,
    strategy: string,
}

interface ServerCampaignSegment {
    segment: Segment;
    ads_cabinet: AdsCabinet;
    platform: Platform;
    is_segment_updatable: boolean;
}

interface ServerCampaignNameForCreate {
    start_date: string;
    end_date: string;
    strategy: string;
    brand_pk: string | undefined;
    shop_id: number | undefined;
    extra_info: string;
    name: string;
}

export interface CampaignsParams {
    page: number;
    per_page: number;
    name?: string;
    name__ilike?: string;
    brand_pk?: string;
    brand_pk__in?: string;
    brand_pk__ilike?: string;
    shop_id?: string;
    shop_id__ilike?: string;
    shop_id__in?: string;
    strategy?: string;
    strategy__ilike?: string;
    start_date?: string;
    end_date?: string;
    is_archived?: boolean;
    cdate?: string;
    order_by?: string;

    cuser__id__in?: string;
    cuser__id?: string;
    cuser__fname__ilike?: string;
    cuser__lname__ilike?: string;
    cuser__order_by?: string;
    cuser__search?: string;

    state?: string;

    ads_cabinet__id__in?: string
    ads_cabinet__source_slug__in?: string;
    campaign_segment__segment_id__in?: string;
    campaign_segment__is_segment_updatable?: boolean;
    state__in?: string;
}
