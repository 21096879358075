import { defineStore } from "pinia";
import { rootGetters } from "@/main";
import { DictionaryItem, getMap } from "@/services/segments/dictionaries";

const endpoints = {
    getStates: { method: "get", url: "/dictionaries/segment_states" },
    getTypes: { method: "get", url: "/dictionaries/segment_types" },
};

export const useSegmentsDictionariesStore = defineStore("segmentsDictionaries", {
    state: () => ({
        types: [] as DictionaryItem[],
        states: [] as DictionaryItem[]
    }),
    getters: {
        typesMap(state) {
            return getMap(state.types)
        },
        statesMap(state) {
            return getMap(state.states)
        },
        statesAllowed(state) {
            if (state.states)
                return state.states.filter(st => st.name !== "deleted");
            return [];
        },
        defaultStates(state) {
            const states: number[] = [];
            if (state.states)
                state.states.forEach(e => {
                    if (!['archived', 'deleted'].includes(e.name)) states.push(e.id);
                })
            return states.join(",");
        }
    },
    actions: {
        async getTypes() {
            await rootGetters.api.proceedRequest(endpoints.getTypes).then((response) => {
                this.types = response.data;
            });
        },
        async getStates() {
            await rootGetters.api.proceedRequest(endpoints.getStates).then((response) => {
                this.states = response.data;
            });
        }
    }
})

