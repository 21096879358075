import store from '@/store';

export default class ErrorHandler {
    showError(error: any, message = "") {
        const commonStore = store()
        if (!message) {
            if (error.response && error.response.data && error.response.data.detail) {
                const detail = error.response.data.detail;
                message = `${error.message}. \n ${detail}. \n Обратитесь в департамент разработки.`
            }

            message = `${error.message}. \n  Обратитесь в департамент разработки или проверьте соединение с интернетом.`
        }

        commonStore.toastGlobalMessage = message;
        commonStore.toastGlobalSeverity = "error";
        commonStore.toastGlobalHead = `Ошибка`;
        commonStore.toastGlobalState = !commonStore.toastGlobalState;

        return Promise.reject(error);
    }
}