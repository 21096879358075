<template>
  <div class="flex">
    <InputNumber
      :modelValue="props.modelValue.interval"
      inputId="horizontal-buttons"
      showButtons
      buttonLayout="horizontal"
      :step="1"
      :min="0"
      :disabled="props.disabled"
      class="fl-1 m-r-12 segment-input"
      @update:modelValue="
      ($event: any) => {
        emit('update:modelValue', {interval: $event, unit: props.modelValue.unit});
      }
    "
    >
      <template #incrementbuttonicon>
        <span class="pi pi-plus" />
      </template>
      <template #decrementbuttonicon>
        <span class="pi pi-minus" />
      </template>
    </InputNumber>
    <Dropdown
      :modelValue="props.modelValue.unit"
      :options="periodNames"
      optionLabel="name"
      optionValue="code"
      class="fl-1"
      :disabled="props.disabled"
      placeholder="Дней"
      @update:modelValue="
      ($event: any) => {
        emit('update:modelValue', {interval: props.modelValue.interval, unit: $event});
      }
    "
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import { getPeriodName } from "@/services/utils/DateParser";

const periodNames = computed(() => {
  return [
    { name: getPeriodName(props.modelValue.interval, "DAY"), code: "DAY" },
    { name: getPeriodName(props.modelValue.interval, "WEEK"), code: "WEEK" },
    { name: getPeriodName(props.modelValue.interval, "MONTH"), code: "MONTH" },
    { name: getPeriodName(props.modelValue.interval, "YEAR"), code: "YEAR" },
  ];
});

const props = defineProps(["modelValue", "disabled"]);
const emit = defineEmits(["update:modelValue"]);
</script>

<style lang="scss" scoped>
:deep(.p-inputnumber.p-inputnumber-buttons-horizontal .p-inputnumber-button) {
  background-color: white;
}

:deep(
    .p-inputnumber.p-inputnumber-buttons-horizontal
      .p-inputnumber-button.p-disabled
  ) {
  background-color: $page-background-color;
}
</style>
