<template>
  <Dialog
    v-model:visible="visibleLocal"
    @hide="$emit('close')"
    modal
    header="Сохранение данных"
    :style="{ width: '30rem' }"
  >
    <span
      >Вы точно хотите выйти из раздела “Создание сегмента” без сохранения?<br />
      Все настроенные данные без сохранения будут утеряны.</span
    >
    <div class="flex buttons">
      <Button
        type="button"
        label="Сохранить сегмент"
        :disabled="blocked"
        @click="$emit('createSegment')"
      ></Button>
      <Button
        type="button"
        label="Выйти"
        severity="secondary"
        :disabled="blocked"
        @click="$emit('exit')"
      ></Button>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

const props = defineProps<{
  visible: boolean;
  blocked: boolean;
}>();

const visibleLocal = ref(props.visible);
watch(
  () => props.visible,
  (newValue) => {
    visibleLocal.value = newValue;
  }
);
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 20px;
  button {
    flex: 1;

    &:first-child {
      margin-right: 20px;
    }
  }
}
</style>
