import { formatDateToYYYYMMDD } from "../utils/DateParser";
import { extractTopLevelBracketExpressions } from "../utils/Strings";
import { objectToString, parseStringToObject } from "../utils/Objects";

const filterVersion = "v1";
const groupFilterRegex = /\)&\(|\)\|\(/;
const operators = new Map([
    ["&", "and"],
    ["|", "or"],
]);

export class SegmentFilter {
    filter: Filters;
    stringFilter = "";

    constructor(
        filter: Filters = {
            version: filterVersion,
            filters_by_ids: { operands: [] },
            filters_for_db: { operands: [] },
        }
    ) {
        this.filter = filter;
        this.stringFilter = this.convertFilterToString();
    }

    findDateOperands(filter: Filter = this.filter.filters_by_ids): Operand[] {
        const dateOperands: Operand[] = [];

        if (filter.operands && Array.isArray(filter.operands)) {
            for (const operand of filter.operands) {
                if ("type" in operand && operand.type === "date") {
                    dateOperands.push(operand);
                } else if ("operands" in operand) {
                    dateOperands.push(...this.findDateOperands(operand));
                }
            }
        }
        return dateOperands;
    }

    setFilterByStr(str: string) {
        this.filter.filters_by_ids = this.convertStringToFilter(str);
    }

    setFilterBySegment(segment: Segment) {
        let filter: Filter;
        let filterForDB: Filter;

        if (segment.conditionsGroups.length > 1) {
            filter = { operator: "or", operands: [] };
            filterForDB = { operator: "or", operands: [] };
        }

        segment.conditionsGroups.forEach((group) => {
            const groupFilter = this.createBaseGroupFilter(group);
            const groupFilterForDB = JSON.parse(JSON.stringify(groupFilter));

            this.addCategoryAndCatalogData(groupFilter, groupFilterForDB, group);

            if (segment.conditionsGroups.length > 1) {
                filter.operands.push(groupFilter);
                filterForDB.operands.push(groupFilterForDB);
            } else {
                filter = { ...groupFilter };
                filterForDB = { ...groupFilterForDB };
            }

            this.updateFilters(filter, filterForDB);
        });
    }

    createBaseGroupFilter(group: ConditionsGroup): Filter {
        const groupFilter: Filter = {
            operator: "and",
            operands: [],
        };

        if (group.period.start && group.isFixedDate) {
            this.addPeriodToFilter(groupFilter, group.period.start, "gte");
        }

        if (group.period.end && group.isFixedDate) {
            this.addPeriodToFilter(groupFilter, group.period.end, "lte");
        }

        if (group.flexible_period.start.interval > 0 && group.isFlexibleDate) {
            this.addFlexiblePeriodToFilter(
                groupFilter,
                group.flexible_period.start,
                "gte"
            );
        }

        if (group.flexible_period.end.interval > 0 && group.isFlexibleDate) {
            this.addFlexiblePeriodToFilter(
                groupFilter,
                group.flexible_period.end,
                "lte"
            );
        }

        return groupFilter;
    }

    addCategoryAndCatalogData(
        groupFilter: Filter,
        groupFilterForDB: Filter,
        group: ConditionsGroup
    ) {
        this.addCategoryToFilter(groupFilter, groupFilterForDB, group);
        this.addCatalogDataToFilter(groupFilter, group, false);
        this.addCatalogDataToFilter(groupFilterForDB, group, true);
    }

    updateFilters(filter: Filter, filterForDB: Filter) {
        this.filter.filters_by_ids = filter;
        this.filter.filters_for_db = filterForDB;
        this.stringFilter = this.convertFilterToString();
    }

    addCatalogDataToFilter(groupFilter: Filter, group: any, withNames: boolean) {
        if (withNames) {
            this.addToFilter(groupFilter, group, "shop", "shop_pk", "shop_pk");
            this.addToFilter(groupFilter, group, "brand", "brand_name", "brand");
        } else {
            this.addToFilter(groupFilter, group, "shop", "id", "shop");
            this.addToFilter(groupFilter, group, "brand", "brand_pk", "brand");
        }
    }

    addToFilter(
        groupFilter: Filter,
        obj: any,
        obj_type: string,
        attr: string,
        type: string
    ) {
        if (obj[obj_type].length > 0) {
            const values = obj[obj_type].map((elem: any) =>
                this.parseStrToInt(elem[attr])
            );
            groupFilter.operands.push({ type: type, value: values, attribute: "in" });
        }
    }

    addPeriodToFilter(groupFilter: Filter, date: Date, attribute: string) {
        groupFilter.operands.push({
            type: "date",
            value: formatDateToYYYYMMDD(date),
            attribute: attribute,
        });
    }

    addFlexiblePeriodToFilter(
        groupFilter: Filter,
        date: FlexiblePeriodItem,
        attribute: string
    ) {
        groupFilter.operands.push({
            type: "date",
            value: { interval: date.interval, unit: date.unit },
            attribute: attribute,
        });
    }

    getSegmentConditionsByFilter(filter: Filter = this.filter.filters_by_ids) {
        const operands = filter.operands;
        const conditionsGroups: ConditionsGroup[] = [];

        if (operands.length > 0 && "operands" in operands[0]) {
            operands.forEach((operand) => {
                if ("operands" in operand)
                    conditionsGroups.push(this.parseFilterGroup(operand.operands));
            });
        } else {
            conditionsGroups.push(this.parseFilterGroup(operands));
        }
        return conditionsGroups;
    }

    parseFilterGroup(operands: any) {
        const conditionsGroup: any = {
            category: [],
            brand: [],
            shop: [],
            period: {
                start: null,
                end: null,
            },
            flexible_period: {
                start: {
                    interval: 0,
                    unit: "DAY",
                },
                end: {
                    interval: 0,
                    unit: "DAY",
                },
            },
            isFixedDate: true,
            isFlexibleDate: false,
        };
        operands.forEach((operand: Operand) => {
            if (["category", "shop", "brand"].includes(operand.type)) {
                conditionsGroup[operand.type] = operand.value;
            } else if (operand.type == "date") {
                if (operand.attribute == "gte") {
                    if (typeof operand.value != 'object') {
                        conditionsGroup.period.start = new Date(operand.value);
                        this.setDateType(conditionsGroup, true);
                    }
                    else {
                        conditionsGroup.flexible_period.start = { ...operand.value };
                        this.setDateType(conditionsGroup, false);
                    }
                } else {
                    if (typeof operand.value != 'object')
                        conditionsGroup.period.end = new Date(operand.value);
                    else
                        conditionsGroup.flexible_period.end = { ...operand.value }
                }
            }
        });

        return conditionsGroup;
    }

    setDateType(conditionsGroup: any, isFixed: boolean) {
        conditionsGroup.isFixedDate = isFixed;
        conditionsGroup.isFlexibleDate = !isFixed;
    }

    convertFilterToString(filter: Filter = this.filter.filters_by_ids): string {
        const operands = filter.operands.filter(e => {
            if ("operands" in e) return true;
            return "value" in e && (e.type == "date" || e.value.length > 0);
        });
        const parts = operands.map((operand) => {
            if ("operands" in operand) {
                return `(${this.convertFilterToString(operand)})`;
            } else {
                let values = "";
                if (Array.isArray(operand.value)) {
                    values = operand.value.join(",");
                } else if (typeof operand.value == "object") {
                    values = objectToString(operand.value);
                } else {
                    values = operand.value;
                }
                return `${operand.type}:${operand.attribute}:${values}`;
            }
        });

        const operator = filter.operator === "and" ? "&" : "|";
        return `(${parts.join(`${operator}`)})`;
    }

    convertStringToFilter(str: string): Filter {
        const operands = [];
        let operator: string | undefined = "";
        let parts: string[] = [];

        if (str.match(groupFilterRegex)) {
            parts = extractTopLevelBracketExpressions(str);
            operator = str[parts[0].length + 2];
            parts = parts.map((e) => {
                if (e[0] != "(") return `(${e})`;
                return e;
            });
        } else {
            parts = str.slice(1, -1).split(/&|\|/);
            operator = parts.length > 1 ? str[parts[0].length + 1] : "&";
        }

        for (let i = 0; i < parts.length; i++) {
            operands.push(this.parseOperand(parts[i]));
        }

        operator = operators.get(operator);
        return { operands, operator };
    }

    parseOperand(part: string) {
        if (part[0] == "(") {
            return this.convertStringToFilter(part);
        } else {
            const [type, attribute, value] = part.split(":");
            let values: any;
            if (value.includes("[")) {
                values = parseStringToObject(value);
            }
            else if (value.includes(",") || attribute == "in") {
                values = value.split(",");
            } else {
                values = value
            }
            if (Array.isArray(values) && values[0] != "")
                values = values.map((e) => {
                    if (Number.isInteger(+e)) return parseInt(e);
                    return e;
                });
            if (values[0] == "") {
                values = [];
            }
            return {
                type,
                attribute,
                value: values,
            };
        }
    }

    getOperandsByCategory(category: any) {
        const operands: (Operand | Filter)[] = [];
        Object.keys(category).forEach((key) => {
            const keys = key.split("-");
            if (keys[0] == "") {
                if (category[key].length)
                    operands.push({ type: "c1", value: category[key], attribute: "in" });
            } else {
                const ops = { operands: [] as any[], operator: "and" };
                let i = 0;
                for (i; i < keys.length; i++) {
                    if (keys[i].length)
                        ops.operands.push({
                            type: `c${i + 1}`,
                            value: [keys[i]],
                            attribute: "in",
                        });
                }
                if (category[key].length)
                    ops.operands.push({
                        type: `c${i + 1}`,
                        value: category[key],
                        attribute: "in",
                    });
                operands.push(ops);
            }
        });
        return operands;
    }

    addCategoryToFilter(groupFilter: Filter, groupFilterForDB: Filter, obj: any) {
        const values: number[] = [];
        const category: any = { "": [] as string[] };

        for (const key in obj.category) {
            const parentKeyParts = key.split("-");
            const catId = parentKeyParts.pop();
            const parentKey = parentKeyParts.join("-");

            if (
                catId != "0" &&
                obj.category[key].checked &&
                !(obj.category[parentKey] && obj.category[parentKey].checked)
            ) {
                if (catId) {
                    values.push(parseInt(catId));
                    if (!(parentKeyParts.join("-") in category))
                        category[parentKeyParts.join("-")] = [];
                    category[parentKeyParts.join("-")].push(catId);
                }
            }
        }

        const operands = this.getOperandsByCategory(category);

        if (operands.length == 1) {
            groupFilterForDB.operands.push(operands[0]);
        } else if (operands.length > 1) {
            groupFilterForDB.operands.push({ operator: "or", operands: operands });
        }

        if (values.length)
            groupFilter.operands.push({
                type: "category",
                value: values,
                attribute: "in",
            });
    }

    parseStrToInt(e: string) {
        if (Number.isInteger(+e)) return parseInt(e);
        return e;
    }
}

export interface Operand {
    type: string;
    value: any;
    attribute: string;
}

export interface Filter {
    operands: Array<Filter | Operand>;
    operator?: string;
}

export interface Filters {
    filters_by_ids: Filter;
    filters_for_db?: Filter;
    version: string;
}

export interface SelectData {
    name: string;
    code: string;
}

export interface ConditionsGroup {
    period: Period;
    flexible_period: FlexiblePeriod;
    category: any[];
    brand: any[];
    shop: any[];
    isFixedDate: boolean,
    isFlexibleDate: boolean,
}

export interface DropdownData {
    name: string;
    value: string;
}

export interface Period {
    start: Date | null;
    end: Date | null;
}

export interface FlexiblePeriod {
    start: FlexiblePeriodItem;
    end: FlexiblePeriodItem;
}

export interface FlexiblePeriodItem {
    interval: number;
    unit: string;
}

interface Segment {
    name: string;
    type: DropdownData;
    conditionsGroups: ConditionsGroup[];
}
