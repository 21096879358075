<template>
  <div class="counter-container">
    <div class="text">Счётчик аудитории</div>
    <div class="count">{{ formatNumber(countAudience) }}</div>
    <Button
      label="Обновить"
      severity="secondary"
      icon="pi pi-refresh"
      class="refresh-button"
      @click="updateFilter"
      :disabled="!isReadyToCount"
      :loading="isLoading"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import Button from "primevue/button";

import { useCategoriesStore } from "@/store/catalog_data/categories";
import { useSegmentsStore } from "@/store/segments/segments";
import { formatNumber } from "@/services/utils/Strings";

import { getFilterWithCategories } from "@/services/utils/Objects";

const categoryStore = useCategoriesStore();
const segmentStore = useSegmentsStore();

const props = defineProps<{
  filter: any;
  isReadyToCount: boolean;
  settedCount: number;
}>();

const emits = defineEmits(["updateAudienceCount"]);

const isLoading = ref(false);
const countAudience = ref(props.settedCount);

watch(
  () => props.settedCount,
  (newValue) => {
    countAudience.value = newValue;
  }
);

const updateFilter = () => {
  isLoading.value = true;
  segmentStore
    .getAudienceFilter(
      getFilterWithCategories(props.filter, categoryStore.categoriesMap)
    )
    .then((count) => {
      countAudience.value = count;
      emits("updateAudienceCount", count);
    })
    .finally(() => {
      isLoading.value = false;
    });
};
</script>

<style lang="scss" scoped>
.counter-container {
  width: 240px;
  background-color: $main-link-color;
  border-radius: 16px;
  padding: 12px 21px;

  .text {
    line-height: 1rem;
    color: $main-light-color;
    margin-top: 14px;
  }

  .count {
    padding: 22px 0;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
    color: white;
    letter-spacing: 0.28px;
  }

  .refresh-button {
    color: $main-font-color;
    background: #fff;
    border-color: #fff;
    width: 100%;
    justify-content: center;

    margin-bottom: 14px;

    :deep(.p-button-label) {
      flex: none;
    }
  }
}
</style>
