<template>
  <MultiSelect
    :modelValue="props.modelValue"
    :options="items"
    display="chip"
    :optionLabel="optionName.label"
    :placeholder="isSegmentSetted ? 'Не выбрано' : 'Выберите значение'"
    :class="isSegmentSetted ? 'hidden' : ''"
    :filter="!isSegmentSetted && !notSearch"
    @filter="onFilterItems"
    :option-value="useIdOnly ? optionName.pk : undefined"
    appendTo="self"
    @before-show="beforeShowItems"
    :showToggleAll="!isSegmentSetted"
    @show="showItems"
    @update:modelValue="
      ($event) => {
        emit('update:modelValue', $event);
        catalogDataSettings.isLoadingFirst = false;
      }
    "
    :loading="catalogDataSettings.isLoading"
    :emptyFilterMessage="
      catalogDataSettings.isLoading
        ? 'Поиск вариантов...'
        : 'Результатов не найдено'
    "
  >
    <template #footer>
      <div class="p-10">{{ props.modelValue.length }} выбрано</div>
    </template>

    <template v-if="catalogDataSettings.isLoading" #value>
      <div :class="props.modelValue.length != 0 ? 'any-catalog' : ''">
        &nbsp;
      </div>
    </template>
    <template #option="slotProps">
      <div v-if="!catalogDataSettings.isLoading">
        {{ slotProps.option[optionName.label] }}
      </div>
      <div v-else>
        <div><Skeleton width="10rem" class="mb-2"></Skeleton></div>
      </div>
    </template>
  </MultiSelect>
</template>

<script setup lang="ts">
import MultiSelect from "primevue/multiselect";
import Skeleton from "primevue/skeleton";

import { ref, watch, onMounted } from "vue";
import { useCatalogData } from "./useCatalogData";

const props = defineProps([
  "modelValue",
  "isSegmentSetted",
  "selectType",
  "useIdOnly",
  "notSearch",
]);

const catalogDataSettings = ref({
  isLoading: false,
  filterStr: "",
  debounceTimeout: null as number | null,
  isLoadingFirst: true,
  optionName: "",
  optionValue: "",
  isLoadedAll: false,
});

const {
  store,
  items,
  optionName,
  addScrollHundler,
  updateSettings,
  onFilterItems,
  getSortingArray,
  sendItemRequest,
  replaceItems,
} = useCatalogData(
  props.selectType,
  catalogDataSettings.value,
  props.useIdOnly
);

const emit = defineEmits(["update:modelValue"]);

let element: Element | null = null;

watch(
  () => catalogDataSettings.value.filterStr,
  () => {
    store.sortItems(getSortingArray(props.modelValue));
    if (catalogDataSettings.value.debounceTimeout)
      clearTimeout(catalogDataSettings.value.debounceTimeout);
    catalogDataSettings.value.debounceTimeout = setTimeout(
      sendItemRequest,
      1000,
      props.modelValue
    );
  },
  { immediate: true }
);

watch(
  () => catalogDataSettings.value,
  (newValue) => {
    updateSettings(newValue);
  }
);

watch(
  () => props.modelValue,
  (newValue) => {
    if (catalogDataSettings.value.isLoadingFirst) {
      catalogDataSettings.value.isLoading = true;
      store.getItems("", newValue, true, true).then(() => {
        catalogDataSettings.value.isLoadingFirst = false;
        const items = replaceItems(newValue);
        if (items.length) emit("update:modelValue", items);
        setTimeout(() => {
          catalogDataSettings.value.isLoading = false;
        }, 200);
      });
    }
  }
);

onMounted(() => {
  if (!items.value.length) {
    store.getItems("", getSortingArray(props.modelValue), true);
  }
});

const beforeShowItems = () => {
  store.sortItems(getSortingArray(props.modelValue));
};

const showItems = () => {
  element = document.querySelectorAll(
    ".p-multiselect-panel .p-multiselect-items-wrapper"
  )[0];
  if (element) addScrollHundler(element);
};
</script>

<style lang="scss">
.p-multiselect.hidden .p-multiselect-panel .p-multiselect-items {
  pointer-events: none;
  background-color: #f7f7fd;
}

.p-multiselect.p-multiselect-chip.hidden .p-multiselect-token svg {
  display: none;
}

.any-catalog {
  padding: 3.5px 0;
}
</style>
