<template>
    <div class="logo">
        <img src="@/assets/images/logo_white.svg" alt="logo">
    </div>
</template>

<style scoped lang="scss">
.logo {
    position: relative;
    height: 100%;
    background: rgb(103, 49, 230);
    border-radius: 20px;

    img {
        position: absolute;
        width: 295px;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
    }
}
</style>