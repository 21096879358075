<template>
  <Calendar
    id="date"
    placeholder="Выберите дату"
    panelClass="not-extended"
    :model-value="modelValue"
    aria-describedby="date-help"
    showIcon
    iconDisplay="input"
    inputId="icondisplay"
    :max-date="dayWeekBefore()"
    :disabled="disabled"
    :selectOtherMonths="true"
    @show="onCalendarShow()"
    @hide="onCloseCalendar()"
    @month-change="onMonthChanged()"
    @update:modelValue="
      ($event: any) => {
        emit('update:modelValue', $event)
      }
    "
  >
  </Calendar>
  <div id="tooltip" class="tooltip-calendar">
    Вы можете выбрать дату минимум через неделю от текущей даты.
  </div>
</template>

<script lang="ts" setup>
import Calendar from "primevue/calendar";
import { dayWeekBefore } from "@/services/utils/DateValidator";

defineProps(["modelValue", "disabled"]);
const emit = defineEmits(["update:modelValue"]);

const onCalendarShow = () => {
  createCalendarTooltips();
};

const onMonthChanged = () => {
  setTimeout(() => {
    createCalendarTooltips();
  }, 500);
};

const onCloseCalendar = () => {
  const elems = document.querySelectorAll(".tooltip-calendar");
  elems.forEach((element) => {
    if (element instanceof HTMLElement) {
      element.style.display = "none";
    }
  });
};

const createCalendarTooltips = () => {
  const elems = document.querySelectorAll(
    "td[data-pc-group-section='tablebodycell'] span.p-disabled"
  );
  elems.forEach((element) => {
    if (element instanceof HTMLElement) {
      element.addEventListener("click", (event: MouseEvent) => {
        showTooltip(
          event,
          "Вы не можете выбрать дату позднее чем неделя назад от текущей даты."
        );
      });
      element.style.pointerEvents = "all";
    }
  });
};

function showTooltip(event: MouseEvent, message: string) {
  const tooltip = document.getElementById("tooltip");
  if (tooltip) {
    tooltip.innerText = message;
    tooltip.style.left = `${event.pageX + 15}px`;
    tooltip.style.top = `${event.pageY + 15}px`;
    tooltip.style.display = "block";
    setTimeout(() => {
      tooltip.style.display = "none";
    }, 3000);
  }
}
</script>

<style lang="scss" scoped>
.tooltip-calendar {
  display: none;
  position: fixed;
  z-index: 100000;
  background: #fff;
  border: 1px solid #eaeaf0;
  padding: 8px;
  box-shadow: 0px 4px 10.2px 0px rgba(0, 0, 0, 0.24);
  font-size: 12px;
  width: 170px;
  line-height: 14px;
  border-radius: 8px;
}
</style>
