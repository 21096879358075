<template>
  <Calendar
    placeholder="Выберите дату"
    panelClass="not-extended"
    :model-value="modelValue"
    aria-describedby="date-help"
    showIcon
    :invalid="isInvalid"
    iconDisplay="input"
    inputId="icondisplay"
    :disabled="disabled"
    :min-date="minDate"
    :selectOtherMonths="true"
    @update:modelValue="
        ($event: any) => {
          emit('update:modelValue', $event)
        }
      "
  >
  </Calendar>
</template>

<script lang="ts" setup>
import Calendar from "primevue/calendar";

defineProps(["modelValue", "disabled", "isInvalid", "minDate"]);
const emit = defineEmits(["update:modelValue"]);
</script>
