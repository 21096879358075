<template>
  <TopPanel>
    <BreadCrumbs :items="items" class="s-bread" />
    <div class="button-container">
      <Button
        label="Сохранить сегмент"
        class="segment-save-button m-l-16 m-r-16"
        :disabled="!isCreateButtonEnabled"
        @click="createSegment"
        :loading="isLoading"
      />
    </div>
  </TopPanel>
</template>

<script setup lang="ts">
import { ref } from "vue";

import BreadCrumbs from "@/shared/menu/BreadCrumbs.vue";
import Button from "primevue/button";
import TopPanel from "@/widgets/sidebars/TopPanel.vue";

defineProps<{
  isCreateButtonEnabled: boolean;
  isLoading: boolean;
}>();

const emit = defineEmits(["createSegment"]);

const items = ref([
  { label: "Сегмент", route: "listSegments" },
  { label: "Создание сегмента" },
]);

const createSegment = () => {
  emit("createSegment");
};
</script>

<style lang="scss" scoped>
.s-bread {
  margin-left: 18px;
}

.button-container {
  flex: 1;

  display: flex;
  justify-content: end;

  margin: 0 20px;
}

.segment-save-button {
  width: 240px;
}
</style>
