<template>
  <div class="tooltip-in-table" @mouseover="setTooltipPosition">
    <div class="elems">
      <span v-for="item in slotProps" :key="item[itemKey]">
        {{ item[itemValue] }}
      </span>
    </div>
    <div class="elems-text" :class="slotProps.length == 0 ? 'empty' : ''">
      <span v-for="item in slotProps" :key="`text-${item[itemKey]}`">
        {{ item[itemValue] }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps(["slotProps", "items", "itemKey", "itemValue"]);

const setTooltipPosition = (event: any) => {
  if (event.path) {
    const parentNode = event.path.filter(
      (e: any) => e.classList && e.classList.contains("tooltip-in-table")
    )[0];

    const docWidth = document.documentElement.clientWidth;
    const docHeight = document.documentElement.clientHeight;
    const rectParent = parentNode.getBoundingClientRect();
    const rectChild = parentNode.children[1].getBoundingClientRect();

    const chX =
      rectParent.x + rectChild.width > docWidth
        ? docWidth - rectChild.width - 20
        : rectParent.x;

    let chY = rectParent.bottom + 10;
    if (chY + rectChild.height > docHeight) {
      chY = rectParent.y - 10 - rectChild.height;
    }
    parentNode.children[1].style.top = chY + "px";
    parentNode.children[1].style.left = chX + "px";
  }
};
</script>

<style lang="scss" scoped>
.tooltip-in-table {
  position: relative;

  .elems {
    display: flex;
    height: 22px;
    overflow-x: hidden;

    &:hover {
      cursor: default;
    }
  }

  .elems,
  .elems-text {
    span {
      background-color: #eaeaf0;
      padding: 4px;
      border-radius: 4px;
      font-size: 12px;
      line-height: 13px;
      margin-right: 8px;
    }
  }

  .elems-text {
    display: flex;
    flex-wrap: wrap;
    position: fixed;

    visibility: hidden;
    width: min-content;
    min-width: 250px;
    background-color: white;
    text-align: center;
    border-radius: 4px;
    padding: 8px 8px 0 8px;

    border-bottom: 1px solid #eaeaf0;
    box-shadow: 0px 4px 16.8px 0px rgba(0, 0, 0, 0.25);

    opacity: 0;
    transition: opacity 0.3s;

    z-index: 1001;

    &.empty {
      padding: 0;
    }

    span {
      margin-bottom: 8px;
    }
  }

  &:hover .elems-text {
    visibility: visible;
    opacity: 1;
  }
}
</style>
