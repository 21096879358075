<template>
  <div class="empty-message">
    <span class="pi pi-shop header-icon"></span>
    <div class="header">Пока тут пусто</div>
    <div class="text">
      <slot name="text">
        Для того чтобы увидеть список созданных сегментов, кликните на кнопку
        "Создать сегмент". <br />
        Далее укажите фильтры и сохраните новый сегмент.
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.empty-message {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .text {
    width: 504px;
    text-align: center;
  }
}

.header-icon {
  font-size: 3.2rem;
  color: #ceccd2;
}

.header {
  font-size: 1.42rem;
  font-weight: bold;

  padding-top: 20px;
  padding-bottom: 15px;
}

.text {
  color: #a19cab;
}
</style>
