<template>
  <div class="panel-container">
    <div class="panel">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.panel-container {
  height: 57px;

  position: fixed;
  top: 72px;
  left: 252px;
  right: 12px;
  min-width: 743px;

  background-color: $page-background-color;

  z-index: 1000;
}

.panel {
  height: 100%;

  display: flex;
  align-items: center;

  background-color: white;
  border-radius: 8px;
}
</style>
